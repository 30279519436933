import Cookies from 'js-cookie';

const SAVED_DATA_STORAGE_KEY = 'PICKLEBALL_SIDEKICK_DATA';

type PersistedState = {
  userId?: number;
  token?: string;
  showCompleted?: boolean;
};
export const getPersistedState = (): PersistedState => {
  const savedDataStringFromCookies = Cookies.get(SAVED_DATA_STORAGE_KEY);
  if (typeof savedDataStringFromCookies === 'string') {
    try {
      return JSON.parse(savedDataStringFromCookies) as PersistedState;
    } catch (e) {
      return {};
    }
  }
  return {};
};

export const persistShowCompleted = (showCompleted: boolean) => {
  const state = getPersistedState();
  state.showCompleted = showCompleted;
  const stringifiedObject = JSON.stringify(state);
  Cookies.set(SAVED_DATA_STORAGE_KEY, stringifiedObject, {
    expires: 3650,
  });
};

export const persistUserIdAndToken = (
  userId: number | undefined,
  token: string | undefined,
) => {
  const state = getPersistedState();
  if (userId && token) {
    state.userId = userId;
    state.token = token;
  } else {
    delete state.userId;
    delete state.token;
  }
  const stringifiedObject = JSON.stringify(state);
  Cookies.set(SAVED_DATA_STORAGE_KEY, stringifiedObject, {
    expires: 3650,
  });
};
