import Box from '@mui/material/Box';
import React from 'react';

import sample from '#Client/images/sample-video.png';

type Props = {
  videoKey: string;
  startTime?: number;
};

export const YoutubeVideo = ({videoKey, startTime}: Props) =>
  navigator.onLine ? (
    <Box overflow="hidden" paddingTop="56.25%" position="relative">
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        frameBorder="0"
        height="360"
        referrerPolicy="strict-origin-when-cross-origin"
        src={`https://www.youtube.com/embed/${videoKey}${
          startTime ? `?start=${startTime}` : ''
        }`}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          border: 0,
          width: '100%',
          height: '100%',
        }}
        title="YouTube video player"
        width="640"
      />
    </Box>
  ) : (
    <img height="360" src={sample} />
  );
