import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React from 'react';

import {useAppState} from '#Client/context/AppState';
import {
  Match,
  MATCH_FORMAT_ID_NAME_MAP,
  MATCH_ROUND_ID_NAME_MAP,
} from '#Common/types';

const getSingleScoreTableCell = (
  score1: number | null | undefined,
  score2: number | null | undefined,
) => {
  if (
    score1 === null ||
    score1 === undefined ||
    score2 === null ||
    score2 === undefined ||
    (score1 === 0 && score2 === 0)
  ) {
    return null;
  }
  return (
    <ScoreTableCell
      sx={{backgroundColor: score1 > score2 ? 'var(--color-3)' : '#fff'}}
    >
      <Box
        alignItems="center"
        display="flex"
        height="30px"
        justifyContent="center"
        width="30px"
      >
        {score1}
      </Box>
    </ScoreTableCell>
  );
};

type Props = {
  match: Omit<Match, 'id'>;
};
export const ScoreTable = ({match}: Props) => {
  const appState = useAppState();
  const {
    state: {playerIdNameMap, tournamentIdNameMap},
  } = appState;

  const {
    teamAPlayer1Id,
    teamAPlayer2Id,
    teamAGame1Score,
    teamAGame2Score,
    teamAGame3Score,
    teamAGame4Score,
    teamAGame5Score,
    teamBPlayer1Id,
    teamBPlayer2Id,
    teamBGame1Score,
    teamBGame2Score,
    teamBGame3Score,
    teamBGame4Score,
    teamBGame5Score,
  } = match;
  const teamAName = `${playerIdNameMap.get(teamAPlayer1Id)}${
    teamAPlayer2Id && playerIdNameMap.get(teamAPlayer2Id)
      ? `/${playerIdNameMap.get(teamAPlayer2Id)}`
      : ''
  }`;
  const teamBName = `${playerIdNameMap.get(teamBPlayer1Id)}${
    teamBPlayer2Id && playerIdNameMap.get(teamBPlayer2Id)
      ? `/${playerIdNameMap.get(teamBPlayer2Id)}`
      : ''
  }`;
  return (
    <Box display="inline-block">
      <Box
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.87)',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottom: 'none',
          padding: 0.5,
        }}
      >
        <Box>
          <Typography variant="h6">
            {tournamentIdNameMap.get(match.tournamentId)}
          </Typography>
        </Box>
        <Box>
          <Typography>
            {MATCH_FORMAT_ID_NAME_MAP.get(match.formatId)} &bull;{' '}
            {MATCH_ROUND_ID_NAME_MAP.get(match.roundId)}
          </Typography>
        </Box>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <ScoreTableNameCell>{teamAName}</ScoreTableNameCell>
            {getSingleScoreTableCell(teamAGame1Score, teamBGame1Score)}
            {getSingleScoreTableCell(teamAGame2Score, teamBGame2Score)}
            {getSingleScoreTableCell(teamAGame3Score, teamBGame3Score)}
            {getSingleScoreTableCell(teamAGame4Score, teamBGame4Score)}
            {getSingleScoreTableCell(teamAGame5Score, teamBGame5Score)}
          </TableRow>
          <TableRow>
            <ScoreTableNameCell>{teamBName}</ScoreTableNameCell>
            {getSingleScoreTableCell(teamBGame1Score, teamAGame1Score)}
            {getSingleScoreTableCell(teamBGame2Score, teamAGame2Score)}
            {getSingleScoreTableCell(teamBGame3Score, teamAGame3Score)}
            {getSingleScoreTableCell(teamBGame4Score, teamAGame4Score)}
            {getSingleScoreTableCell(teamBGame5Score, teamAGame5Score)}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

const ScoreTableCell = styled(TableCell)(() => ({
  [`&&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: 0,
    border: '1px solid rgba(0, 0, 0, 0.87)',
    textAlign: 'center',
    // backgroundColor: '#fff',
  },
}));

const ScoreTableNameCell = styled(ScoreTableCell)(() => ({
  [`&&.${tableCellClasses.body}`]: {
    paddingLeft: 4,
    textAlign: 'left',
    width: '100%',
  },
}));
