import {SxProps} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import cloneDeep from 'lodash/fp/cloneDeep';
import React from 'react';

import {CircularPercentage} from '#Client/components/CircularPercentage';
import {
  StyledTableCell,
  StyledTableRow,
} from '#Client/components/TableComponents';
import {
  FetchVideosFilter,
  // getRecentVideos,
} from '#Common/api';
import {Video} from '#Common/types';
import {timeAgo} from '#Common/util';

export type VideoData = {
  videos: Video[];
  totalQuantity: number;
  processedQuantity: number;
};

type Props = {
  fetchVideosFilter: FetchVideosFilter;
  isLoading: boolean;
  selectedVideo: Video;
  videoData: VideoData;
  onSetFetchVideosFilter: (filter: FetchVideosFilter) => void;
  onSelectedVideoChange: (video: Video) => void;
};

export const VideosTable = ({
  fetchVideosFilter,
  isLoading,
  selectedVideo,
  videoData,
  onSetFetchVideosFilter,
  onSelectedVideoChange,
}: Props) => {
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    const fetchVideosFilterCopy = cloneDeep(fetchVideosFilter);
    fetchVideosFilterCopy.page = page;
    onSetFetchVideosFilter(fetchVideosFilterCopy);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const fetchVideosFilterCopy = cloneDeep(fetchVideosFilter);
    fetchVideosFilterCopy.rowsPerPage = parseInt(event.target.value, 10);
    fetchVideosFilterCopy.page = 0;
    onSetFetchVideosFilter(fetchVideosFilterCopy);
  };

  const handleShowProcessedVideosChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fetchVideosFilterCopy = cloneDeep(fetchVideosFilter);
    fetchVideosFilterCopy.showProcessedVideos = event.target.checked;
    onSetFetchVideosFilter(fetchVideosFilterCopy);
  };

  return (
    <>
      <Stack alignItems="center" direction="row">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={fetchVideosFilter.showProcessedVideos}
                onChange={handleShowProcessedVideosChange}
              />
            }
            label="Show processed videos"
          />
        </FormGroup>
        <Typography>
          Processed {videoData.processedQuantity} / {videoData.totalQuantity}
        </Typography>
        <CircularPercentage
          value={(100 * videoData.processedQuantity) / videoData.totalQuantity}
        />
        <TablePagination
          component="div"
          count={videoData.totalQuantity}
          page={fetchVideosFilter.page}
          rowsPerPage={fetchVideosFilter.rowsPerPage}
          rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
          sx={{marginLeft: 'auto'}}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Stack>
      <TableContainer
        component={Paper}
        sx={{maxHeight: 400, mt: 2, opacity: isLoading ? 0.5 : 1}}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Done</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {videoData.videos.map((video, index) => {
              const {id, title, broadcastedDate, processedDate} = video;
              const isSelected = id === selectedVideo.id;
              const baseCellStyles: SxProps = {cursor: 'pointer'};
              const selectedCellStyles: SxProps = isSelected
                ? {backgroundColor: 'var(--color-5)'}
                : {};
              const cellStyles = {...baseCellStyles, ...selectedCellStyles};
              return (
                <StyledTableRow
                  key={`${index}-${title}`}
                  onClick={() => onSelectedVideoChange(video)}
                >
                  <StyledTableCell sx={cellStyles}>{title}</StyledTableCell>
                  <StyledTableCell sx={cellStyles}>
                    {broadcastedDate}
                  </StyledTableCell>
                  <StyledTableCell sx={cellStyles}>
                    {processedDate === null ? 'No' : timeAgo(processedDate)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
