import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';
import type {ActionFunction} from 'react-router';
import {useLoaderData} from 'react-router-dom';

import {ScoreTable} from '#Client/components/ScoreTable';
import {Layout} from '#Client/features/Layout';
import {findMatchesByPlayer, getPlayerDetails} from '#Common/api';
import {Match, Player} from '#Common/types';

export const PlayerPage = () => {
  // const appState = useAppState();
  // const {
  //   state: {tournamentIdNameMap},
  // } = appState;

  const {player, matches} = useLoaderData() as {
    player: Player;
    matches: Match[];
  };

  return (
    <Layout>
      <Typography mb={2} variant="h4">
        {player.name}
      </Typography>
      {matches.length === 0
        ? null
        : matches.map(match => (
            <Paper key={match.id}>
              <Box m={1} p={1}>
                <ScoreTable match={match} />
              </Box>
            </Paper>
          ))}
    </Layout>
  );
};

export const playerDetailsLoader: ActionFunction = async ({params}) => {
  const playerId = parseInt(params.playerId || '0');
  if (!playerId) {
    throw 'Error';
  }
  const [playerDetailsResponse, findMatchesByPlayerResponse] =
    await Promise.all([
      getPlayerDetails(playerId),
      findMatchesByPlayer(playerId),
    ]);
  // const playerDetailsResponse = await ;
  if (!playerDetailsResponse.success || !findMatchesByPlayerResponse.success) {
    throw 'Error';
  }
  return {
    player: playerDetailsResponse.player,
    matches: findMatchesByPlayerResponse.matches,
  };
};
