// import
// {
//   getCart,
// getItems,
// getOrders,
// getStoreItems,
// getStores,
// } from '#Common/api';
// import {getAllPlayers, getAllTournaments} from '#Common/api';
import {getAllPlayers, getAllTournaments} from '#Common/api';
import {InitialData} from '#Common/types';

export function assertValueIsEnum<T>(
  value: unknown,
  possibleValues: readonly T[],
): value is T {
  return possibleValues.includes(value as T);
}

export const validateEmail = (email: string): boolean => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
};

export const sleep = async (time_ms: number) =>
  new Promise(resolve => setTimeout(resolve, time_ms));

export const isFormatDoubles = (formatId: number): boolean =>
  [1, 2, 3].includes(formatId);

const units = [
  {label: 'year', seconds: 31536000},
  {label: 'month', seconds: 2592000},
  {label: 'week', seconds: 604800},
  {label: 'day', seconds: 86400},
  {label: 'hour', seconds: 3600},
  {label: 'minute', seconds: 60},
  {label: 'second', seconds: 1},
];

const calculateTimeDifference = (time: number) => {
  for (const {label, seconds} of units) {
    const interval = Math.floor(time / seconds);
    if (interval >= 1) {
      return {
        interval: interval,
        unit: label,
      };
    }
  }
  return {
    interval: 0,
    unit: '',
  };
};

export const formatDate = (date: string): string =>
  date === null
    ? '--'
    : new Date(date).toLocaleDateString('en-us', {
        // weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });

export const formatDateRange = (
  date1String: string,
  date2String: string,
): string => {
  const getMonth = (date: string) => new Date(date).getMonth();
  const getYear = (date: string) => new Date(date).getFullYear();
  const getDay = (date: string) => new Date(date).getDate();

  const year1 = getYear(date1String);
  const year2 = getYear(date2String);
  if (year1 !== year2) {
    return `${formatDate(date1String)} - ${formatDate(date2String)}`;
  }

  const month1 = getMonth(date1String);
  const month2 = getMonth(date2String);
  if (month1 !== month2) {
    return `${new Date(date1String).toLocaleDateString('en-us', {
      //jan 1 - feb 5, 2004
      // year: 'numeric',
      month: 'short',
      day: 'numeric',
    })} - ${formatDate(date2String)}`;
  }

  const day1 = getDay(date1String);
  const day2 = getDay(date2String);
  if (day1 === day2) {
    return formatDate(date1String);
  }

  // jan 1 - 2, 2004
  const date2 = new Date(date2String);
  return `${new Date(date1String).toLocaleDateString('en-us', {
    // year: 'numeric',
    month: 'short',
    day: 'numeric',
  })} - ${date2.getDate()}, ${date2.getFullYear()}`;
};

export const timeAgo = (date: string | number | Date) => {
  const time = Math.floor(
    (new Date().valueOf() - new Date(date).valueOf()) / 1000,
  );
  const {interval, unit} = calculateTimeDifference(time);
  const suffix = interval === 1 ? '' : 's';
  return `${interval} ${unit}${suffix} ago`;
};

export const getInitialData = async (): Promise<InitialData> => {
  const [
    playersResults,
    tournamentsResults,
    // itemsResults,
    // storeItemsResults,
    // cartResults,
    // ordersResults,
  ] = await Promise.all([
    getAllPlayers(),
    getAllTournaments(),
    // getItems(),
    // getStoreItems(),
    // getCart(),
    // getOrders(),
  ]);

  return {
    players: playersResults.success ? playersResults.players : [],
    tournaments: tournamentsResults.success
      ? tournamentsResults.tournaments
      : [],
    // stores: storesResults.success ? storesResults.stores : [],
    // itemIds: itemsResults.success ? itemsResults.itemIdsWithMap.itemIds : [],
    // itemsMap: itemsResults.success ? itemsResults.itemIdsWithMap.itemsMap : {},
    // storeItems: storeItemsResults.success ? storeItemsResults.storeItems : [],
    // cart: cartResults.success ? cartResults.cart : {},
    // orders: ordersResults.success ? ordersResults.orders : [],
  };
};

export const replaceSingleQuoteWithTwo = (input: string) =>
  input.replace(/'/gm, "''");
