import Box from '@mui/material/Box';
import React from 'react';
import {Link} from 'react-router-dom';

export const NavLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => (
  <Link
    style={{
      color: 'inherit',
      textDecoration: 'none',
    }}
    to={to}
  >
    <Box
      sx={{
        display: 'flex',
        color: 'white',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {children}
    </Box>
  </Link>
);
