import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import cloneDeep from 'lodash/fp/cloneDeep';
import React, {useState} from 'react';

import {ProgressButton} from '#Client/components/ProgressButton';
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableRowPaired,
} from '#Client/components/TableComponents';
import {TwoLabelSwitch} from '#Client/components/TwoLabelSwitch';
import {useAppState} from '#Client/context/AppState';
import {createPlayers, getExistingPlayerNames} from '#Common/api';
import {Candidate} from '#Common/types';
import {assertValueIsEnum} from '#Common/util';

export const genderToggleValues = ['women', 'men', 'mixed'] as const;
export type GenderToggleValue = (typeof genderToggleValues)[number];

export const TEST_VALUE = `Catherine Parenteau (Naples, FL)
Anna Leigh Waters (Village of Golf, )
Anna Bright (Pompano Beach, FL)
Rachel Rohrabacher (Tampa, FL)
Jorja Johnson (Boynton Beach, AK)
Vivian Glozman (Bellevue, WA)
Judit Castillo (Natchitoches, LA)
Ewa Radzikowska (Marietta, GA)
Lea Jansen (Valleyford, WA)
Tina Pisnik (Bradenton, FL)
Tyra Hurricane Black (Delray Beach, FL)
Jessie Irvine (Culver City, CA)
Simone Jardim (Naples, FL)
Allyce Jones (Pleasant Grove, U)
Brooke Buckner (Concord, NC)
Chao Yi Wang (Kaohsiung city)
Lacy Schneemann (Redondo Beach, CA)
Jade Kawamoto (Camby, IN)
Alix Truong (Riverton, UT)
Jackie Kawamoto (Camby, IN)
Lauren Stratman (Austin, TX)
Milan Rane (Boca Raton, FL)
Sarah Ansboury (Hilton head, SC)
Regina Goldberg (Delray Beach, FL)
Dominique Schaefer (Boca Raton, FL)
Sofia Sewing (Coconut creek, FL)
Martina Frantova (Bonita Springs, F)
Samantha Parker (Grapevine, TX)
Lingwei Kong (wichita, KS)
Bobbi Oshiro (Plantation, FL)
Hannah Blatt (Delray Beach, FL)
Madeline Schulte (Wichita, KS)
Amanda Hendry (Port St. Lucie, F)
Kate Fahey (New York, NY)
Jamie Haas (Kailua, HI)
Olivia McMillan (Charleston, FL)
Genie Erokhina (Aliso Viejo, CA)
Nicole Eugenio (Woodland Hills, C)
Marcela Hones (Solana Beach, CA)
Leah Tauber (Cardiff, CA)
Alex Walker (West Vancouver, B)
Angie Walker (West Vancouver, B)
Tamaryn Emmrich (Bradenton, FL)
Kaitlyn Christian (Los Angeles, CA)
Liz Truluck (Charlotte, NC)
Pierina Imparato (Charlotte, NC)
Cailyn Campbell (Palmetto Bay, FL)
Madalina Grigoriu (South Miami, FL)
Hailee Haymore (Cameron, NC)
Jessica Haywood (Jackson Springs, )
Ava Ignatowich (Delray Beach, FL)
Camila Zilveti (Austin, TX)
Maebry Jones (Red Bank, TN)
Elina Geut (Knoxville, TN)
Sarah Hua (Boca Raton, FL)
Cass Hoag (Jacksonville, FL)
Genie Bouchard (Miami Beach, FL)
Layne Sleeth (Markham, ON)
Chandra Kusuma (Summerville, SC)
Helena Jansen (Boone, NC)
Emilia Schmidt (Brisbane city, Ql)
Alia Brown (Alpharetta, GA)
Ting Chieh Wei (Baltimore, MD)
Amanda Studnicki (FL)`;

// const rollUpResults = (results: string[]): string => {
//   const output: Record<string, number> = {};
//   results.forEach(result => {
//     if (result in output) {
//       output[result]++;
//     } else {
//       output[result] = 1;
//     }
//   });
//   return JSON.stringify(output);
// };

export const ManagePlayerIngestion = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [genderToggle, setGenderToggle] = useState<GenderToggleValue>('women');
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  // const [candidateResults, setCandidateResults] = useState<string[]>([]);
  const [existingPlayersNameIsMaleMap, setExistingPlayersNameIsMaleMap] =
    useState<Map<string, boolean>>(new Map<string, boolean>());

  const {setSnackbarText} = useAppState();

  const setCandidatesAreMale = (changes: [number, boolean][]) => {
    const candidatesCopy = cloneDeep(candidates);
    changes.forEach(([index, isMale]) => {
      candidatesCopy[index].isMale = isMale;
    });
    setCandidates(candidatesCopy);
  };

  // const setCandidateIsMale = (index: number, isMale: boolean) => {
  //   const candidatesCopy = cloneDeep(candidates);
  //   candidatesCopy[index].isMale = isMale;
  //   setCandidates(candidatesCopy);
  // };

  const handleGenderToggleChange = (value: GenderToggleValue) => {
    setGenderToggle(value);
    const candidatesCopy = cloneDeep(candidates);
    if (value === 'mixed') {
      candidatesCopy.forEach((candidate, index) => {
        candidate.isMale = index % 2 === 0;
      });
    } else {
      candidatesCopy.forEach(candidate => {
        candidate.isMale = value === 'men';
      });
    }
    setCandidates(candidatesCopy);
  };

  const processContent = async (content: string) => {
    const newCandidates: Candidate[] = [];
    const lines = content.split('\n');
    lines.forEach(line => {
      const parts = line.split(' (');
      if (parts.length > 1) {
        newCandidates.push({
          name: parts[0],
          description: '',
          homeTown: parts[1].substring(0, parts[1].length - 1),
          isMale: true,
        });
      }
    });
    const existingPlayersResponse = await getExistingPlayerNames(
      newCandidates.map(c => c.name),
    );
    console.log('existingPlayersResponse: ', existingPlayersResponse);
    if (existingPlayersResponse.success) {
      setExistingPlayersNameIsMaleMap(
        new Map<string, boolean>(
          existingPlayersResponse.results.map(result => [
            result.name,
            result.isMale,
          ]),
        ),
      );
    }
    setCandidates(newCandidates);
  };

  const handleTextChange = async (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const {value} = e.target;
    setTextFieldValue(value);
    await processContent(value);
    // setCandidateResults([]);
  };

  const handleUploadClick = async () => {
    setIsLoading(true);
    const createPlayersResponse = await createPlayers(candidates);
    if (createPlayersResponse.success) {
      const created = createPlayersResponse.results.filter(
        result => result === 'Created',
      ).length;
      const alreadyExisted = createPlayersResponse.results.filter(
        result => result === 'Already existed',
      ).length;
      setSnackbarText(
        `Created ${created} new players. ${alreadyExisted} already existed.`,
      );
    }
    setIsLoading(false);
  };

  const RowComponent =
    genderToggle === 'mixed' ? StyledTableRowPaired : StyledTableRow;

  return (
    <Paper>
      <Box p={2}>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          sx={{marginBottom: 2}}
        >
          <Typography>
            Rows:{' '}
            {textFieldValue.split('\n').filter(line => line !== '').length}
            {/* {rollUpResults(candidateResults)} */}
          </Typography>
          <Stack alignItems="center" direction="row" ml="auto">
            <Typography>
              M: {candidates.filter(c => c.isMale).length} F:{' '}
              {candidates.filter(c => !c.isMale).length}
            </Typography>
            <ProgressButton
              isLoading={isLoading}
              variant="contained"
              onClick={handleUploadClick}
            >
              Upload
            </ProgressButton>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2}>
          <TextField
            label="Paste players here"
            maxRows={32}
            multiline
            sx={{flex: 1}}
            value={textFieldValue}
            onChange={handleTextChange}
          />
          <Box sx={{flex: 2}}>
            <TableContainer
              component={Paper}
              sx={{maxHeight: 800, opacity: isLoading ? 0.5 : 1}}
            >
              <Table size="small" stickyHeader>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Home town</StyledTableCell>
                    <StyledTableCell>
                      <Stack alignItems="center" direction="row" gap={2}>
                        <Box>Gender </Box>
                        <Paper>
                          <ToggleButtonGroup
                            exclusive
                            size="small"
                            value={genderToggle}
                            onChange={event => {
                              if (
                                !(event.target instanceof HTMLButtonElement)
                              ) {
                                return;
                              }
                              const newVideoType = event.target.value;
                              if (
                                !assertValueIsEnum<GenderToggleValue>(
                                  newVideoType,
                                  genderToggleValues,
                                )
                              ) {
                                return;
                              }
                              handleGenderToggleChange(newVideoType);
                            }}
                          >
                            {genderToggleValues.map(val => (
                              <ToggleButton
                                color="primary"
                                key={val}
                                value={val}
                              >
                                {val}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        </Paper>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>Result</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {candidates.map(({name, homeTown, isMale}, index) => {
                    return (
                      <RowComponent key={`${name}-${homeTown}-${isMale}`}>
                        <StyledTableCell>{name}</StyledTableCell>
                        <StyledTableCell>{homeTown}</StyledTableCell>
                        <StyledTableCell>
                          {existingPlayersNameIsMaleMap.has(name) ? (
                            <Typography>
                              {existingPlayersNameIsMaleMap.get(name)
                                ? 'Man'
                                : 'Woman'}
                            </Typography>
                          ) : (
                            <TwoLabelSwitch
                              checked={isMale}
                              colorLeft="red"
                              colorRight="orange"
                              labelLeft="Woman"
                              labelRight="Man"
                              onChange={checked => {
                                const changesToApply: [number, boolean][] = [
                                  [index, checked],
                                ];
                                if (genderToggle === 'mixed') {
                                  changesToApply.push([
                                    index + (index % 2 === 0 ? 1 : -1),
                                    !checked,
                                  ]);
                                }
                                setCandidatesAreMale(changesToApply);
                              }}
                            />
                          )}
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          {candidateResults[index]}
                        </StyledTableCell> */}
                      </RowComponent>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};
