import React from 'react';
import {Outlet} from 'react-router-dom';

import {Footer} from '#Client/components/Footer';
// import {BottomNavigationBar} from '#Client/components/BottomNavigationBar';
import {MainPageSnackbar} from '#Client/components/MainSnackbar';
import {TopNavBar} from '#Client/components/TopNavBar';
// import {useAppState} from '#Client/context/AppState';

export const App = () => {
  // const appState = useAppState();
  // const {
  //   state: {user},
  // } = appState;
  return (
    <>
      <TopNavBar />
      <Outlet />
      {/* {user ? <BottomNavigationBar /> : null} */}
      <Footer />
      <MainPageSnackbar />
    </>
  );
};
