import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import React from 'react';

import {MatchFormat, matchFormats} from '#Common/types';
import {assertValueIsEnum} from '#Common/util';

type Props = {
  id?: string;
  label?: string;
  value: MatchFormat;
  onChange: (value: MatchFormat) => void;
};

export const FormatPicker = ({
  id,
  label = 'Match Format',
  value,
  onChange,
}: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const {value} = event.target;
    if (!assertValueIsEnum<MatchFormat>(value, matchFormats)) {
      return;
    }
    onChange(value);
  };
  return (
    <FormControl fullWidth>
      {label ? <InputLabel id={id}>{label}</InputLabel> : null}
      <Select label={label} labelId={id} value={value} onChange={handleChange}>
        {matchFormats.map(matchFormat => (
          <MenuItem key={matchFormat} value={matchFormat}>
            {matchFormat}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
