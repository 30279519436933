import {SxProps} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import cloneDeep from 'lodash/fp/cloneDeep';
import React from 'react';

import {FormatPicker} from '#Client/components/FormatPicker';
import {RoundPicker} from '#Client/components/RoundPicker';
import {ScoreTable} from '#Client/components/ScoreTable';
// import {CircularPercentage} from '#Client/components/CircularPercentage';
import {
  StyledTableCell,
  StyledTableRow,
} from '#Client/components/TableComponents';
import {YoutubeVideo} from '#Client/components/YoutubeVideo';
import {useAppState} from '#Client/context/AppState';
import {TournamentPicker} from '#Client/features/TournamentPicker/TournamentPicker';
import {FetchMatchesFilter} from '#Common/api';
import {Match, MatchFormat, MatchRound, MatchWithVideoKey} from '#Common/types';

export type MatchData = {
  matches: MatchWithVideoKey[];
  totalQuantity: number;
  processedQuantity: number;
};

type Props = {
  fetchMatchesFilter: FetchMatchesFilter;
  isLoading: boolean;
  selectedMatch?: Match;
  isAdmin?: boolean;
  matchData: MatchData;
  onSetFetchMatchesFilter: (filter: FetchMatchesFilter) => void;
  onSelectedMatchChange?: (match: Match) => void;
};

export const MatchesTable = ({
  fetchMatchesFilter,
  isLoading,
  selectedMatch,
  isAdmin,
  matchData,
  onSelectedMatchChange,
  onSetFetchMatchesFilter,
}: Props) => {
  const appState = useAppState();
  const {
    state: {tournaments},
  } = appState;
  // const [selectedTournament, setSelectedTournament] = useState<Tournament>(
  //   tournaments[0],
  // );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    const fetchMatchesFilterCopy = cloneDeep(fetchMatchesFilter);
    fetchMatchesFilterCopy.page = page;
    onSetFetchMatchesFilter(fetchMatchesFilterCopy);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const fetchMatchesFilterCopy = cloneDeep(fetchMatchesFilter);
    fetchMatchesFilterCopy.rowsPerPage = parseInt(event.target.value, 10);
    fetchMatchesFilterCopy.page = 0;
    onSetFetchMatchesFilter(fetchMatchesFilterCopy);
  };

  const handleTournamentChange = (tournamentId: number) => {
    const fetchMatchesFilterCopy = cloneDeep(fetchMatchesFilter);
    fetchMatchesFilterCopy.tournamentId = tournamentId;
    fetchMatchesFilterCopy.page = 0;
    onSetFetchMatchesFilter(fetchMatchesFilterCopy);
  };

  const handleFormatChange = (format: MatchFormat) => {
    const fetchMatchesFilterCopy = cloneDeep(fetchMatchesFilter);
    fetchMatchesFilterCopy.format = format;
    fetchMatchesFilterCopy.page = 0;
    onSetFetchMatchesFilter(fetchMatchesFilterCopy);
  };

  const handleRoundChange = (round: MatchRound) => {
    const fetchMatchesFilterCopy = cloneDeep(fetchMatchesFilter);
    fetchMatchesFilterCopy.round = round;
    fetchMatchesFilterCopy.page = 0;
    onSetFetchMatchesFilter(fetchMatchesFilterCopy);
  };

  return (
    <>
      <Paper>
        <Box p={1}>
          <Stack
            alignItems="center"
            direction="row"
            gap={1}
            sx={{flexDirection: {xs: 'column', md: 'row'}}}
          >
            {isAdmin ? (
              <TournamentPicker
                label="Tournament"
                selectedTournament={
                  tournaments.find(
                    t => t.id === fetchMatchesFilter.tournamentId,
                  ) || tournaments[0]
                }
                onChange={tournament => {
                  if (tournament) {
                    handleTournamentChange(tournament.id);
                  }
                }}
              />
            ) : null}
            <FormatPicker
              value={fetchMatchesFilter.format}
              onChange={handleFormatChange}
            />
            <RoundPicker
              value={fetchMatchesFilter.round}
              onChange={handleRoundChange}
            />
          </Stack>
          <TablePagination
            component="div"
            count={matchData.totalQuantity}
            page={fetchMatchesFilter.page}
            rowsPerPage={fetchMatchesFilter.rowsPerPage}
            rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
            sx={{marginLeft: 'auto'}}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </Paper>
      <TableContainer
        component={Paper}
        sx={{
          mt: 2,
          maxHeight: {xs: '30vh', md: 700},
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell sx={{width: '100%'}}>Match</StyledTableCell>
              <StyledTableCell>Video</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {matchData.matches.map(match => {
              const {id, videoKey} = match;
              const isSelected = id === selectedMatch?.id;
              const baseCellStyles: SxProps = {cursor: 'pointer'};
              const selectedCellStyles: SxProps = isSelected
                ? {backgroundColor: 'secondary.light'}
                : {};
              const cellStyles = {...baseCellStyles, ...selectedCellStyles};
              return (
                <StyledTableRow
                  key={id}
                  onClick={() => onSelectedMatchChange?.(match)}
                >
                  <StyledTableCell sx={cellStyles}>
                    <ScoreTable match={match} />
                  </StyledTableCell>
                  <StyledTableCell sx={cellStyles}>
                    {videoKey === null ? (
                      <Typography>No video available.</Typography>
                    ) : (
                      <Box width="400px">
                        <YoutubeVideo videoKey={videoKey} />
                      </Box>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
