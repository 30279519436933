export type Player = {
  id: number;
  name: string;
  description: string;
  homeTown: string;
  isMale: boolean;
};

export type Candidate = Omit<Player, 'id'>;

export type Tournament = {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  playersIngestedDate: string;
  matchesIngestedDate: string;
};

export type Video = {
  id: number;
  title: string;
  website: 'yt';
  videoKey: string;
  broadcastedDate: string;
  processedDate: string | null;
};

export type NewVideo = Omit<Video, 'id'>;

export type InitialData = {
  players: Player[];
  tournaments: Tournament[];
};

export type User = {
  id: number;
  name: string;
  email: string;
  // favorites: {[key: number]: string};
  token: string;
  // checkinMethod: CheckinMethod;
  verifiedAt: string;
  accountId: number;
};

export const videoTypes = [
  'match',
  'highlight',
  'recap',
  'preview',
  'other',
] as const;
export type VideoType = (typeof videoTypes)[number];

export const matchFormats = [
  "Women's Doubles",
  "Men's Doubles",
  'Mixed Doubles',
  "Women's Singles",
  "Men's Singles",
] as const;
export type MatchFormat = (typeof matchFormats)[number];

export const matchRounds = [
  'Round of 64',
  'Round of 32',
  'Round of 16',
  'Quarter Finals',
  'Semi Finals',
  'Bronze Medal Match',
  'Gold Medal Match',
] as const;
export type MatchRound = (typeof matchRounds)[number];

// 0: TeamB won. 1: TeamA won. null: unknown
export type MatchOutcome = 0 | 1 | null;

export type Match = {
  id: number;
  videoId: number | null;
  tournamentId: number;
  startTime: number | null;
  formatId: number;
  roundId: number;
  isComplete: boolean;
  teamAPlayer1Id: number;
  teamAPlayer2Id: number | null;
  teamBPlayer1Id: number;
  teamBPlayer2Id: number | null;
  matchOutcome: MatchOutcome;
  teamAGame1Score: number | null;
  teamAGame2Score: number | null;
  teamAGame3Score: number | null;
  teamAGame4Score: number | null;
  teamAGame5Score: number | null;
  teamBGame1Score: number | null;
  teamBGame2Score: number | null;
  teamBGame3Score: number | null;
  teamBGame4Score: number | null;
  teamBGame5Score: number | null;
};

export type NewMatch = Omit<Match, 'id'>;

export type MatchWithVideoKey = Match & {videoKey: string};

export const MATCH_ROUND_NAME_ID_MAP = new Map<string, number>([
  ['Gold Medal Match', 1],
  ['Bronze Medal Match', 2],
  ['Semi Finals', 3],
  ['Quarter Finals', 4],
  ['Round of 16', 5],
  ['Round of 32', 6],
  ['Round of 64', 7],
]);

export const MATCH_ROUND_ID_NAME_MAP = new Map<number, string>([
  [1, 'Gold Medal Match'],
  [2, 'Bronze Medal Match'],
  [3, 'Semi Finals'],
  [4, 'Quarter Finals'],
  [5, 'Round of 16'],
  [6, 'Round of 32'],
  [7, 'Round of 64'],
]);

export const MATCH_FORMAT_NAME_ID_MAP = new Map<MatchFormat, number>([
  ["Women's Doubles", 1],
  ["Men's Doubles", 2],
  ['Mixed Doubles', 3],
  ["Women's Singles", 4],
  ["Men's Singles", 5],
]);

export const MATCH_FORMAT_ID_NAME_MAP = new Map<number, MatchFormat>([
  [1, "Women's Doubles"],
  [2, "Men's Doubles"],
  [3, 'Mixed Doubles'],
  [4, "Women's Singles"],
  [5, "Men's Singles"],
]);
