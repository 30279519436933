import {SxProps} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react';

import {useAppState} from '#Client/context/AppState';
import {Tournament} from '#Common/types';

type Props = {
  label: string;
  selectedTournament: Tournament;
  sx?: SxProps;
  onChange: (tournament: Tournament | null) => void;
};

export const TournamentPicker = ({
  label,
  selectedTournament,
  sx,
  onChange,
}: Props) => {
  const appState = useAppState();
  const {
    state: {tournaments},
  } = appState;
  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(tournament: Tournament) => tournament.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      options={tournaments}
      renderInput={params => <TextField {...params} label={label} />}
      sx={sx}
      value={selectedTournament}
      onChange={(event, newValue: Tournament | null) => {
        onChange(newValue);
      }}
    />
  );
};
