import MenuIcon from '@mui/icons-material/Menu';
import {alpha, SxProps} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {NavLink} from '#Client/components/NavLink';
import {useAppState} from '#Client/context/AppState';
import logo from '#Client/images/logo.png';
import {persistUserIdAndToken} from '#Common/persistence';

export const TopNavBar = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const appState = useAppState();
  const {state, setUser} = appState;
  const {user} = state;
  // const user = {name: 'Top Nav'};
  const navigate = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSignOutClick = () => {
    setUser(null, 'Your are now logged out');
    persistUserIdAndToken(undefined, undefined);
    navigate('/');
    handleCloseUserMenu();
  };

  const hoverButtonStyles: SxProps = {
    // my: 2,
    color: 'white',
    '&:hover': {
      textDecoration: 'underline',
    },
  };

  // sx={{flexDirection: {xs: 'column', md: 'row'}}}

  const navLinks = [
    {
      to: '/players',
      name: 'Players',
    },
    {
      to: '/tournaments',
      name: 'Tournaments',
    },
  ];

  if (user?.id === 1)
    [
      navLinks.push({
        to: '/manage/videoIngestion',
        name: 'Manage',
      }),
    ];

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'var(--color-1)',
        paddingTop: 'env(safe-area-inset-top)',
        outline: 'none',
      }}
    >
      <Toolbar sx={{gap: 4}} variant="dense">
        <NavLink to="/">
          <Box component="img" maxWidth={48} src={logo} />
        </NavLink>
        <Stack direction="row" gap={4} sx={{display: {xs: 'none', sm: 'flex'}}}>
          {navLinks.map(({to, name}) => (
            <NavLink key={to} to={to}>
              {name}
            </NavLink>
          ))}
        </Stack>
        <Box sx={{display: {xs: 'block', sm: 'none'}}}>
          <IconButton sx={{color: 'white'}} onClick={handleOpenNavMenu}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClick={handleCloseNavMenu}
            onClose={handleCloseNavMenu}
          >
            {navLinks.map(({to, name}) => (
              <MenuItem key={to}>
                <Link to={to}>{name}</Link>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box ml="auto" sx={{flexGrow: 0}}>
          {user ? (
            <>
              <Tooltip title="Open settings" onClick={handleOpenUserMenu}>
                <Stack alignItems="center" direction="row" gap={1}>
                  <Button sx={hoverButtonStyles}>{user.name}</Button>
                </Stack>
              </Tooltip>
              <Menu
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                open={Boolean(anchorElUser)}
                sx={{mt: '45px'}}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleCloseUserMenu}
              >
                <Link
                  style={{color: 'inherit', textDecoration: 'none'}}
                  to="/user-profile"
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">User Profile</Typography>
                  </MenuItem>
                </Link>
                <MenuItem onClick={handleSignOutClick}>
                  <Typography textAlign="center">Sign out</Typography>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Stack direction="row">
              <Button
                sx={hoverButtonStyles}
                onClick={() => {
                  navigate('sign-up');
                }}
              >
                Create account
              </Button>
              <Divider
                flexItem
                orientation="vertical"
                sx={{
                  my: 1,
                  borderColor: theme => alpha(theme.palette.common.white, 0.4),
                }}
                variant="middle"
              />
              <Button
                sx={hoverButtonStyles}
                onClick={() => {
                  navigate('sign-in');
                }}
              >
                Sign in
              </Button>
            </Stack>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
