import {Typography} from '@mui/material';
import React, {useState} from 'react';
import type {ActionFunction} from 'react-router';
import {useLoaderData, useParams} from 'react-router-dom';

import {useAppState} from '#Client/context/AppState';
import {Layout} from '#Client/features/Layout';
import {
  MatchData,
  MatchesTable,
} from '#Client/features/ManagePage/ManageMatches/MatchesTable';
import {
  DEFAULT_FETCH_MATCHES_FILTER,
  FetchMatchesFilter,
  getMatches,
} from '#Common/api';
import {Match, Tournament} from '#Common/types';

export const TournamentPage = () => {
  const params = useParams();
  const tournamentId = parseInt(params.tournamentId || '0');
  const appState = useAppState();
  const {
    state: {tournaments},
  } = appState;
  const initialMatchData = useLoaderData() as MatchData;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matchData, setMatchData] = useState<MatchData>(initialMatchData);
  const [fetchMatchesFilter, setFetchMatchesFilter] =
    useState<FetchMatchesFilter>({
      ...DEFAULT_FETCH_MATCHES_FILTER,
      tournamentId,
    });

  const tournament = tournaments.find(({id}) => id === tournamentId);

  const fetchMatches = async (filter: FetchMatchesFilter) => {
    setIsLoading(true);
    const response = await getMatches(filter);
    setIsLoading(false);
    if (response.success) {
      setMatchData({
        matches: response.matches,
        totalQuantity: response.totalQuantity,
        processedQuantity: response.processedQuantity,
      });
    }
  };

  const handleSetFetchVideosFilter = async (
    newFetchMatchesFilter: FetchMatchesFilter,
  ) => {
    setFetchMatchesFilter(newFetchMatchesFilter);
    fetchMatches(newFetchMatchesFilter);
  };

  if (!tournament) {
    return (
      <Layout>
        <Typography sx={{color: 'error.main'}} textAlign="center" variant="h3">
          Sorry!
        </Typography>
        <Typography sx={{color: 'error.main', marginTop: 4}} textAlign="center">
          This tournament could not be found.
        </Typography>
      </Layout>
    );
  }

  const {matches} = useLoaderData() as {
    tournament: Tournament;
    matches: Match[];
  };

  return (
    <Layout>
      <Typography mb={2} variant="h4">
        {tournament.name}
      </Typography>
      {matches.length === 0 ? (
        <Typography>
          Matches for this tournament haven&apos;t been added yet. Please check
          back again soon.
        </Typography>
      ) : (
        <MatchesTable
          fetchMatchesFilter={fetchMatchesFilter}
          isLoading={isLoading}
          matchData={matchData}
          onSetFetchMatchesFilter={handleSetFetchVideosFilter}
        />
      )}
    </Layout>
  );
};

// export const tournamentDetailsLoader: ActionFunction = async ({params}) => {
//   const tournamentId = parseInt(params.tournamentId || '0');
//   if (!tournamentId) {
//     throw 'Error';
//   }
//   const [tournamentMatchesResponse] = await Promise.all([
//     findMatchesByTournament(tournamentId),
//   ]);
//   if (!tournamentMatchesResponse.success) {
//     throw 'Error';
//   }
//   return {
//     matches: tournamentMatchesResponse.matches,
//   };
// };

export const tournamentDetailsLoader: ActionFunction = async ({params}) => {
  const tournamentId = parseInt(params.tournamentId || '0');
  const matchesResponse = await getMatches({
    ...DEFAULT_FETCH_MATCHES_FILTER,
    tournamentId,
  });
  if (!matchesResponse.success) {
    throw 'Error getting matches';
  }
  return matchesResponse;
};
