import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import React from 'react';
import {Link} from 'react-router-dom';

import {
  StyledTableCell,
  StyledTableRow,
} from '#Client/components/TableComponents';
import {useAppState} from '#Client/context/AppState';
import {Layout} from '#Client/features/Layout';
import {formatDateRange} from '#Common/util';

export const TournamentsPage = () => {
  const appState = useAppState();
  const {
    state: {tournaments},
  } = appState;
  return (
    <Layout>
      <TableContainer component={Paper} sx={{mt: 4}}>
        <Box py={0.5}>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  Tournaments ({tournaments.length})
                </StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {tournaments.map(tournament => (
                <StyledTableRow key={tournament.name}>
                  <StyledTableCell>
                    <Link to={`${tournament.id}`}>{tournament.name}</Link>
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatDateRange(tournament.startDate, tournament.endDate)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </Layout>
  );
};
