import cloneDeep from 'lodash/fp/cloneDeep';
import React, {createContext, useContext, useState} from 'react';

import {InitialData, Player, Tournament, User} from '#Common/types';

// const playerNameIdMap: Map<string, number> = new Map(
//   players.map(player => [player.name, player.id]),
// );

// const playerIdNameMap: Map<number, string> = new Map(
//   players.map(player => [player.id, player.name]),
// );

export type AppState = {
  // cart: CartState;
  players: Player[];
  playerNameIdMap: Map<string, number>;
  playerIdNameMap: Map<number, string>;
  tournaments: Tournament[];
  tournamentIdNameMap: Map<number, string>;
  snackbarText: string | null;
  user: User | null;
  // stores: Store[];
  // itemIds: number[];
  // itemsMap: ItemsMap;
  // storeItems: Set<string>;
  // orders: Order[];
};

type AppStateContextType = {
  state: AppState;
  closeSnackbar: () => void;
  // setCartState: (cartState: CartState) => void;
  // setItems: (itemIdsWithMap: ItemIdsWithMap) => void;
  setSnackbarText: (text: string) => void;
  setTournaments: (tournaments: Tournament[]) => void;
  // setStoreItems: (storeItems: Set<string>) => void;
  // setStores: (stores: Store[]) => void;
  setUser: (user: User | null, snackbarText: string) => void;
  // setOrdersAndCartState: (orders: Order[], cartState: CartState) => void;
  // setItemsAndStoreItems: (
  //   {itemIds, itemsMap}: ItemIdsWithMap,
  //   storeItems: Set<string>,
  // ) => void;
};

const AppStateContext = createContext<AppStateContextType | null>(null);

type AppStateProviderProps = {
  children: React.ReactNode;
  initialData: InitialData;
  user: User | null;
};

// export const DEFAULT_INITIAL_DATA: InitialData = {
//   players: [],
//   tournaments: [],
// };

export const AppStateProvider = ({
  children,
  initialData,
  user,
}: AppStateProviderProps) => {
  // const persistedState = getPersistedState();
  const [state, setState] = useState<AppState>({
    players: initialData.players,
    playerNameIdMap: new Map(
      initialData.players.map(player => [player.name, player.id]),
    ),
    playerIdNameMap: new Map(
      initialData.players.map(player => [player.id, player.name]),
    ),
    tournaments: initialData.tournaments,
    tournamentIdNameMap: new Map(
      initialData.tournaments.map(tournament => [
        tournament.id,
        tournament.name,
      ]),
    ),
    snackbarText: null,
    // stores: initialData.stores,
    // itemIds: initialData.itemIds,
    // itemsMap: initialData.itemsMap,
    // storeItems: new Set<string>(initialData.storeItems),
    // orders: initialData.orders,
    user,
  });

  // const setCartState = (newCart: CartState): void => {
  //   const appStateCopy = cloneDeep(state);
  //   appStateCopy.cart = newCart;
  //   setState(appStateCopy);
  // };

  const setUser = (
    user: User | null,
    snackbarText: string,
    // initialData: InitialData,
  ): void => {
    const appStateCopy = cloneDeep(state);
    appStateCopy.user = user;
    appStateCopy.snackbarText = snackbarText;
    // const {players} = initialData;
    // appStateCopy.players = players;
    // appStateCopy.stores = stores;
    // appStateCopy.itemIds = itemIds;
    // appStateCopy.itemsMap = itemsMap;
    // appStateCopy.storeItems = new Set<string>(storeItems);
    // appStateCopy.orders = orders;
    setState(appStateCopy);
  };

  const setSnackbarText = (text: string): void => {
    const appStateCopy = cloneDeep(state);
    appStateCopy.snackbarText = text;
    setState(appStateCopy);
  };

  const setTournaments = (tournaments: Tournament[]): void => {
    const appStateCopy = cloneDeep(state);
    appStateCopy.tournaments = tournaments;
    (appStateCopy.tournamentIdNameMap = new Map(
      tournaments.map(tournament => [tournament.id, tournament.name]),
    )),
      setState(appStateCopy);
  };

  const closeSnackbar = (): void => {
    const appStateCopy = cloneDeep(state);
    appStateCopy.snackbarText = null;
    setState(appStateCopy);
  };

  // const setItems = ({itemIds, itemsMap}: ItemIdsWithMap): void => {
  //   const appStateCopy = cloneDeep(state);
  //   appStateCopy.itemIds = itemIds;
  //   appStateCopy.itemsMap = itemsMap;
  //   setState(appStateCopy);
  // };

  // const setStores = (stores: Store[]): void => {
  //   const appStateCopy = cloneDeep(state);
  //   appStateCopy.stores = stores;
  //   setState(appStateCopy);
  // };

  // const setStoreItems = (storeItems: Set<string>): void => {
  //   const appStateCopy = cloneDeep(state);
  //   appStateCopy.storeItems = storeItems;
  //   setState(appStateCopy);
  // };

  // const setOrdersAndCartState = (
  //   orders: Order[],
  //   cartState: CartState,
  // ): void => {
  //   const appStateCopy = cloneDeep(state);
  //   appStateCopy.orders = orders;
  //   appStateCopy.cart = cartState;
  //   setState(appStateCopy);
  // };

  // const setItemsAndStoreItems = (
  //   {itemIds, itemsMap}: ItemIdsWithMap,
  //   storeItems: Set<string>,
  // ): void => {
  //   const appStateCopy = cloneDeep(state);
  //   appStateCopy.itemIds = itemIds;
  //   appStateCopy.itemsMap = itemsMap;
  //   appStateCopy.storeItems = storeItems;
  //   setState(appStateCopy);
  // };

  return (
    <AppStateContext.Provider
      value={{
        state,
        closeSnackbar,
        // setCartState,
        // setItems,
        setSnackbarText,
        setTournaments,
        // setStoreItems,
        // setStores,
        setUser,
        // setOrdersAndCartState,
        // setItemsAndStoreItems,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = (): AppStateContextType => {
  const appState = useContext(AppStateContext);
  if (!appState) {
    throw new Error('app state unavailable');
  }
  return appState;
};

// export const useCourts = () => {
//   const appState = useAppState();
//   const {
//     state: {courts},
//   } = appState;
//   return courts;
// };
