import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ProgressButton} from '#Client/components/ProgressButton';
import {useAppState} from '#Client/context/AppState';
import {Layout} from '#Client/features/Layout';
import {signIn} from '#Common/api';
import {persistUserIdAndToken} from '#Common/persistence';
// import {getInitialData} from '#Common/util';

export const SignInPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const appState = useAppState();
  const {setUser} = appState;

  const handleCreateAccountClick = async () => {
    navigate('/sign-up');
  };
  const handleForgotPasswordClick = async () => {
    navigate('/forgot-password');
  };

  const handleSubmitClick = async () => {
    setIsLoading(true);
    const response = await signIn(email, password);
    setIsLoading(false);
    if (response.success) {
      const {user} = response;
      persistUserIdAndToken(user.id, user.token);
      // const initialData = await getInitialData();
      setUser(response.user, 'You are now signed in...');
      navigate('/players');
    } else {
      setUser(null, '?');
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Layout>
      <Paper component={Box} p={4}>
        <Typography color="primary" variant="h4">
          Sign In
        </Typography>
        <Stack gap={3} mt={3}>
          <Stack gap={3}>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="signup-email">Email</InputLabel>
              <Input
                id="signup-email"
                placeholder="jane@doe.com"
                value={email}
                onChange={handleEmailChange}
              />
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="signup-password">Password</InputLabel>
              <Input
                id="signup-password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} justifyContent="space-between" mt={3}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleCreateAccountClick}
            >
              Create Account
            </Button>
            <Button
              color="error"
              variant="outlined"
              onClick={handleForgotPasswordClick}
            >
              Forgot password
            </Button>
            <ProgressButton
              isLoading={isLoading}
              variant="contained"
              onClick={handleSubmitClick}
            >
              Sign in
            </ProgressButton>
          </Stack>
        </Stack>
      </Paper>
    </Layout>
  );
};
