import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import React, {useState} from 'react';

import {ProgressButton} from '#Client/components/ProgressButton';
import {
  StyledTableCell,
  StyledTableRow,
} from '#Client/components/TableComponents';
import {useAppState} from '#Client/context/AppState';
import {
  updateTournamentMatchesIngestedDate,
  updateTournamentPlayersIngestedDate,
} from '#Common/api';
import {formatDate} from '#Common/util';

export const ManageTournaments = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    state: {tournaments},
    setTournaments,
  } = useAppState();

  const handleIngestPlayersClick = async (tournamentId: number) => {
    setIsLoading(true);
    const response = await updateTournamentPlayersIngestedDate(tournamentId);
    if (response.success) {
      setTournaments(response.tournaments);
    }
    setIsLoading(false);
  };

  const handleIngestMatchesClick = async (tournamentId: number) => {
    setIsLoading(true);
    const response = await updateTournamentMatchesIngestedDate(tournamentId);
    if (response.success) {
      setTournaments(response.tournaments);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box pb={2} visibility={isLoading ? 'visible' : 'hidden'}>
        <LinearProgress />
      </Box>
      <Paper>
        <TableContainer component={Paper} sx={{opacity: isLoading ? 0.5 : 1}}>
          <Table size="small" stickyHeader>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Ingestion</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {tournaments.map(tournament => {
                const {
                  id,
                  name,
                  startDate,
                  endDate,
                  playersIngestedDate,
                  matchesIngestedDate,
                } = tournament;
                return (
                  <StyledTableRow key={id}>
                    <StyledTableCell>{name}</StyledTableCell>
                    <StyledTableCell>
                      {formatDate(startDate)} - {formatDate(endDate)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box>
                        Players:{' '}
                        {playersIngestedDate === null ? (
                          <ProgressButton
                            isLoading={isLoading}
                            startIcon={<CheckIcon />}
                            variant="contained"
                            onClick={() => {
                              handleIngestPlayersClick(id);
                            }}
                          >
                            Mark
                          </ProgressButton>
                        ) : (
                          formatDate(playersIngestedDate)
                        )}
                      </Box>
                      <Box mt={2}>
                        Matches:{' '}
                        {matchesIngestedDate === null ? (
                          <ProgressButton
                            isLoading={isLoading}
                            startIcon={<CheckIcon />}
                            variant="contained"
                            onClick={() => {
                              handleIngestMatchesClick(id);
                            }}
                          >
                            Mark
                          </ProgressButton>
                        ) : (
                          formatDate(matchesIngestedDate)
                        )}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
