import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import React from 'react';

type Props = {
  teamAName: string;
  teamBName: string;
  scores: [
    number | '',
    number | '',
    number | '',
    number | '',
    number | '',
    number | '',
    number | '',
    number | '',
    number | '',
    number | '',
  ];
  onSetScore: (index: number, score: number | '') => void;
};

const getSingleScoreTableCell = (
  value: number | '',
  index: number,
  setter: (index: number, score: number | '') => void,
) => {
  return (
    <ScoreTableCell key={index}>
      <Box
        alignItems="center"
        display="flex"
        height="60px"
        justifyContent="center"
        width="60px"
      >
        <TextField
          value={value}
          onChange={event => {
            if (event.target.value === '') {
              setter(index, '');
            }
            const int = parseInt(event.target.value);
            if (!Number.isNaN(int)) {
              setter(index, int);
            }
          }}
        />
      </Box>
    </ScoreTableCell>
  );
};

export const ScoreInputTable = ({
  teamAName,
  teamBName,
  scores,
  onSetScore,
}: Props) => (
  <Table>
    <TableBody>
      <TableRow>
        <ScoreTableNameCell>{teamAName}</ScoreTableNameCell>
        {scores
          .slice(0, 5)
          .map((score, index) =>
            getSingleScoreTableCell(score, index, onSetScore),
          )}
        {/* {getSingleScoreTableCell(teamAGame2Score, teamBGame2Score)}
        {getSingleScoreTableCell(teamAGame3Score, teamBGame3Score)}
        {getSingleScoreTableCell(teamAGame4Score, teamBGame4Score)}
        {getSingleScoreTableCell(teamAGame5Score, teamBGame5Score)} */}
      </TableRow>
      <TableRow>
        <ScoreTableNameCell>{teamBName}</ScoreTableNameCell>
        {scores
          .slice(5, 10)
          .map((score, index) =>
            getSingleScoreTableCell(score, 5 + index, onSetScore),
          )}
        {/* {getSingleScoreTableCell(teamBGame1Score, teamAGame1Score)}
        {getSingleScoreTableCell(teamBGame2Score, teamAGame2Score)}
        {getSingleScoreTableCell(teamBGame3Score, teamAGame3Score)}
        {getSingleScoreTableCell(teamBGame4Score, teamAGame4Score)}
        {getSingleScoreTableCell(teamBGame5Score, teamAGame5Score)} */}
      </TableRow>
    </TableBody>
  </Table>
);

const ScoreTableCell = styled(TableCell)(() => ({
  [`&&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 4,
    border: '1px solid rgba(0, 0, 0, 0.87)',
    textAlign: 'center',
    backgroundColor: '#fff',
  },
}));

const ScoreTableNameCell = styled(ScoreTableCell)(() => ({
  [`&&.${tableCellClasses.body}`]: {
    paddingLeft: 4,
    textAlign: 'left',
    width: '100%',
  },
}));
