import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  value: number;
};

export const CircularPercentage = ({value}: Props) => (
  <Box sx={{position: 'relative', display: 'inline-flex'}}>
    <CircularProgress variant="determinate" />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        color="text.secondary"
        component="div"
        variant="caption"
      >{`${Math.round(value)}%`}</Typography>
    </Box>
  </Box>
);
