import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import React from 'react';
import {Link} from 'react-router-dom';

import {
  StyledTableCell,
  StyledTableRow,
} from '#Client/components/TableComponents';
import {useAppState} from '#Client/context/AppState';
import {Layout} from '#Client/features/Layout';

export const PlayersPage = () => {
  const appState = useAppState();
  const {
    state: {players},
  } = appState;
  return (
    <Layout>
      <TableContainer component={Paper} sx={{mt: 4}}>
        <Table size="small">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Players ({players.length})</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {players.map(player => (
              <StyledTableRow key={player.name}>
                <StyledTableCell>
                  <Link to={`${player.id}`}>{player.name}</Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};
