import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';

import {FormatPicker} from '#Client/components/FormatPicker';
import {ProgressButton} from '#Client/components/ProgressButton';
import {RoundPicker} from '#Client/components/RoundPicker';
import {ScoreInputTable} from '#Client/components/ScoreInputTable';
import {useAppState} from '#Client/context/AppState';
import {PlayerFinder} from '#Client/features/PlayerFinder/PlayerFinder';
import {TournamentPicker} from '#Client/features/TournamentPicker/TournamentPicker';
import {createMatches} from '#Common/api';
import {
  MATCH_FORMAT_NAME_ID_MAP,
  MATCH_ROUND_NAME_ID_MAP,
  MatchFormat,
  MatchRound,
  NewMatch,
  Player,
  Tournament,
} from '#Common/types';
import {assertValueIsEnum} from '#Common/util';

type Score = number | '';
type Scores = [
  Score,
  Score,
  Score,
  Score,
  Score,
  Score,
  Score,
  Score,
  Score,
  Score,
];

const getScore = (scores: Scores, index: number): number | null => {
  const score = scores[index];
  if (score === '') {
    return null;
  }
  return score;
};

export const ManageManual = () => {
  const {
    state: {tournaments},
  } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFormat, setSelectedFormat] =
    useState<MatchFormat>("Women's Doubles");
  const [selectedRound, setSelectedRound] =
    useState<MatchRound>('Bronze Medal Match');
  const [selectedTournament, setSelectedTournament] = useState<Tournament>(
    tournaments[0],
  );
  const [teamAPlayer1, setTeamAPlayer1] = useState<Player | null>(null);
  const [teamAPlayer2, setTeamAPlayer2] = useState<Player | null>(null);
  const [teamBPlayer1, setTeamBPlayer1] = useState<Player | null>(null);
  const [teamBPlayer2, setTeamBPlayer2] = useState<Player | null>(null);
  const [matchOutcome, setMatchOutcome] = useState<0 | 1>(1);
  const [scores, setScores] = useState<Scores>([
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]);

  const teamANameParts: string[] = [];
  teamAPlayer1 !== null && teamANameParts.push(teamAPlayer1.name);
  teamAPlayer2 !== null && teamANameParts.push(teamAPlayer2.name);

  const teamBNameParts: string[] = [];
  teamBPlayer1 !== null && teamBNameParts.push(teamBPlayer1.name);
  teamBPlayer2 !== null && teamBNameParts.push(teamBPlayer2.name);

  const handleSubmit = async () => {
    setIsLoading(true);
    // console.log('teamAPlayer1: ', teamAPlayer1);
    // console.log('teamAPlayer2: ', teamAPlayer2);
    // console.log('teamBPlayer1: ', teamBPlayer1);
    // console.log('teamBPlayer2: ', teamBPlayer2);
    // console.log('scores: ', scores);
    // console.log('selectedFormat: ', selectedFormat);
    // console.log('selectedRound: ', selectedRound);

    // const tournament = tournaments[selectedTournamentIndex];
    // console.log('tournament: ', tournament);
    // createMatchVideo()

    // MATCH_ROUND_NAME_ID_MAP
    // MATCH_FORMAT_NAME_ID_MAP

    if (teamAPlayer1 === null || teamBPlayer1 === null) {
      return;
    }

    const newMatch: NewMatch = {
      videoId: null,
      tournamentId: selectedTournament.id,
      startTime: null,
      formatId: MATCH_FORMAT_NAME_ID_MAP.get(selectedFormat) || -1,
      roundId: MATCH_ROUND_NAME_ID_MAP.get(selectedRound) || -1,
      isComplete: true,
      teamAPlayer1Id: teamAPlayer1.id,
      teamAPlayer2Id: teamAPlayer2?.id || null,
      teamBPlayer1Id: teamBPlayer1.id,
      teamBPlayer2Id: teamBPlayer2?.id || null,
      // 0: TeamB won. 1: TeamA won. null: unknown
      matchOutcome,
      teamAGame1Score: getScore(scores, 0),
      teamAGame2Score: getScore(scores, 1),
      teamAGame3Score: getScore(scores, 2),
      teamAGame4Score: getScore(scores, 3),
      teamAGame5Score: getScore(scores, 4),
      teamBGame1Score: getScore(scores, 5),
      teamBGame2Score: getScore(scores, 6),
      teamBGame3Score: getScore(scores, 7),
      teamBGame4Score: getScore(scores, 8),
      teamBGame5Score: getScore(scores, 9),
    };

    console.log('newMatch: ', newMatch);
    setIsLoading(true);
    const createMatchResponse = await createMatches([newMatch]);
    console.log('createMatchResponse: ', createMatchResponse);
    setIsLoading(false);
  };

  const handleSetScore = (index: number, score: Score) => {
    const newScores: Scores = [...scores];
    newScores[index] = score;
    setScores(newScores);
  };

  return (
    <Paper>
      <Box pb={2} visibility={isLoading ? 'visible' : 'hidden'}>
        <LinearProgress />
      </Box>
      <Stack gap={2} p={2}>
        <TournamentPicker
          label="Tournament"
          selectedTournament={selectedTournament}
          onChange={tournament => {
            if (tournament === null) {
              return;
            }
            setSelectedTournament(tournament);
          }}
        />

        <FormatPicker
          value={selectedFormat}
          onChange={value => setSelectedFormat(value)}
        />
        <RoundPicker
          value={selectedRound}
          onChange={value => setSelectedRound(value)}
        />
        <Stack direction="row" gap={2}>
          <PlayerFinder
            label="Team A Player 1"
            selectedPlayer={teamAPlayer1}
            sx={{flex: 1}}
            onChange={setTeamAPlayer1}
          />
          <PlayerFinder
            label="Team A Player 2"
            selectedPlayer={teamAPlayer2}
            sx={{flex: 1}}
            onChange={setTeamAPlayer2}
          />
        </Stack>
        <Typography>VS</Typography>
        <Stack direction="row" gap={2}>
          <PlayerFinder
            label="Team B Player 1"
            selectedPlayer={teamBPlayer1}
            sx={{flex: 1}}
            onChange={setTeamBPlayer1}
          />
          <PlayerFinder
            label="Team B Player 2"
            selectedPlayer={teamBPlayer2}
            sx={{flex: 1}}
            onChange={setTeamBPlayer2}
          />
        </Stack>
        <ScoreInputTable
          scores={scores}
          teamAName={teamANameParts.join('/')}
          teamBName={teamBNameParts.join('/')}
          onSetScore={handleSetScore}
        />
        <ToggleButtonGroup
          exclusive
          // size="small"
          value={matchOutcome}
          onChange={event => {
            console.log('event: ', event);
            if (!(event.target instanceof HTMLButtonElement)) {
              return;
            }
            const newMatchOutcome = parseInt(event.target.value);
            if (!assertValueIsEnum<0 | 1>(newMatchOutcome, [0, 1])) {
              return;
            }
            setMatchOutcome(newMatchOutcome);
          }}
        >
          <ToggleButton color="primary" value={1}>
            Team A
          </ToggleButton>
          <ToggleButton color="primary" value={0}>
            Team B
          </ToggleButton>
        </ToggleButtonGroup>
        <ProgressButton
          isLoading={isLoading}
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </ProgressButton>
      </Stack>
    </Paper>
  );
};
