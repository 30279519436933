import {SxProps} from '@mui/material';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  checked: boolean;
  colorLeft: string;
  colorRight: string;
  labelLeft: string;
  labelRight: string;
  sx?: SxProps;
  onChange: (checked: boolean) => void;
};

export const TwoLabelSwitch = ({
  checked,
  colorLeft,
  colorRight,
  labelLeft,
  labelRight,
  sx,
  onChange,
}: Props) => (
  <Stack alignItems="center" direction="row" sx={sx}>
    <Typography color={colorLeft}>{labelLeft}</Typography>
    <Switch
      checked={checked}
      sx={{
        '&.MuiSwitch-root .MuiSwitch-switchBase': {
          color: colorLeft,
        },

        '&.MuiSwitch-root .Mui-checked': {
          color: colorRight,
        },
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
      }}
    />
    <Typography color={colorRight}>{labelRight}</Typography>
  </Stack>
);
