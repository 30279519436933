import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, {FocusEvent, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {InputField} from '#Client/components/InputField';
import {ProgressButton} from '#Client/components/ProgressButton';
import {useAppState} from '#Client/context/AppState';
import {Layout} from '#Client/features/Layout';
import {signUp} from '#Common/api';
import {persistUserIdAndToken} from '#Common/persistence';
import {assertValueIsEnum, validateEmail} from '#Common/util';

const fieldNames = ['Name', 'Email', 'Password', 'Repeat password'] as const;
type FieldName = (typeof fieldNames)[number];

export const SignUpPage = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [errors, setErrors] = useState<Set<FieldName>>(new Set<FieldName>());
  const [submissionErrorMessage, setSubmissionErrorMessage] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const appState = useAppState();
  const {setUser} = appState;

  const navigate = useNavigate();

  const blurField = (event: FocusEvent<HTMLInputElement>) => {
    const {id, value} = event.target;

    if (!assertValueIsEnum(id, fieldNames)) {
      return;
    }
    const errorsCopy = new Set(errors);
    if (value === '') {
      errorsCopy.add(id);
    } else {
      errorsCopy.delete(id);
    }

    setErrors(errorsCopy);
  };

  const validateForm = (): boolean => {
    const errorsCopy = new Set(errors);
    ['Name', 'Email', 'Password', 'Repeat password'].forEach(fieldName => {
      if (
        (fieldName === 'Name' && name === '') ||
        (fieldName === 'Email' && email === '') ||
        (fieldName === 'Password' && password === '') ||
        (fieldName === 'Repeat password' && repeatPassword === '')
      ) {
        errorsCopy.add(fieldName);
      }
    });
    setErrors(errorsCopy);
    return errorsCopy.size === 0;
  };

  const handleSubmitClick = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const response = await signUp(name, email, password);
      setIsLoading(false);
      if (response.success) {
        const {user} = response;
        persistUserIdAndToken(user.id, user.token);
        setUser(response.user, 'Your account has been created');
        navigate('/players');
      } else {
        setSubmissionErrorMessage(response.reason);
      }
    }
  };

  return (
    <Layout>
      <Paper component={Box} p={4}>
        <Typography color="primary" variant="h4">
          Sign up
        </Typography>
        <Stack gap={3}>
          <InputField
            error={errors.has('Name')}
            label="Name"
            placeholder="Jane Doe"
            value={name}
            onBlur={blurField}
            onChange={event => setName(event.target.value)}
          />
          <Box>
            <InputField
              error={errors.has('Email')}
              label="Email"
              placeholder="jane@doe.com"
              value={email}
              onBlur={blurField}
              onChange={event => setEmail(event.target.value)}
            />
            {email === '' || validateEmail(email) ? null : (
              <FormHelperText sx={{color: theme => theme.palette.error.main}}>
                Email must be valid
              </FormHelperText>
            )}
            {submissionErrorMessage === '' ? null : (
              <FormHelperText sx={{color: theme => theme.palette.error.main}}>
                {submissionErrorMessage}
              </FormHelperText>
            )}
          </Box>
          <InputField
            error={errors.has('Password')}
            label="Password"
            type="password"
            value={password}
            onBlur={blurField}
            onChange={event => setPassword(event.target.value)}
          />
          <Box>
            <InputField
              error={errors.has('Repeat password')}
              label="Repeat password"
              type="password"
              value={repeatPassword}
              onBlur={blurField}
              onChange={event => setRepeatPassword(event.target.value)}
            />
            {password === repeatPassword ? null : (
              <FormHelperText sx={{color: theme => theme.palette.error.main}}>
                Passwords must match
              </FormHelperText>
            )}
          </Box>
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" mt={3}>
          <Link to="/sign-in">
            <ProgressButton
              color="secondary"
              isLoading={isLoading}
              variant="outlined"
            >
              Sign in instead
            </ProgressButton>
          </Link>
          <ProgressButton
            isLoading={isLoading}
            variant="contained"
            onClick={handleSubmitClick}
          >
            Create Account
          </ProgressButton>
        </Stack>
      </Paper>
    </Layout>
  );
};
