import '#Client/baseline.plain.css';

import {CssBaseline} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {AppStateProvider} from '#Client/context/AppState';
import {App} from '#Client/features/App';
import {SignInPage} from '#Client/features/Authenticate/SignInPage';
import {SignUpPage} from '#Client/features/Authenticate/SignUpPage';
import {HomePage} from '#Client/features/HomePage/HomePage';
import {ManageManual} from '#Client/features/ManagePage/ManageManual';
import {
  ManageMatches,
  manageMatchesLoader,
} from '#Client/features/ManagePage/ManageMatches/ManageMatches';
import {ManageMatchIngestion} from '#Client/features/ManagePage/ManageMatchIngestion';
import {ManagePage} from '#Client/features/ManagePage/ManagePage';
import {ManagePlayerIngestion} from '#Client/features/ManagePage/ManagePlayerIngestion';
import {ManageTournaments} from '#Client/features/ManagePage/ManageTournaments';
import {ManageVideoIngestion} from '#Client/features/ManagePage/ManageVideoIngestion';
import {
  ManageVideos,
  manageVideosLoader,
} from '#Client/features/ManagePage/ManageVideos/ManageVideos';
import {
  playerDetailsLoader,
  PlayerPage,
} from '#Client/features/PlayerPage/PlayerPage';
import {PlayersPage} from '#Client/features/PlayersPage/PlayersPage';
import {
  tournamentDetailsLoader,
  TournamentPage,
} from '#Client/features/TournamentPage/TournamentPage';
import {TournamentsPage} from '#Client/features/TournamentsPage/TournamentsPage';
import {reSignIn} from '#Common/api';
import {getPersistedState} from '#Common/persistence';
import {User} from '#Common/types';
import {getInitialData} from '#Common/util';

Sentry.init({
  dsn: 'https://d12c9199f300b061ab29455fd62fa3f4@o1123717.ingest.us.sentry.io/4507958637625344',
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
if (!container) {
  throw 'root not found';
}

const getUser = async (): Promise<User | null> => {
  const persistedState = getPersistedState();
  const {userId, token} = persistedState;
  if (userId && token) {
    const reSignInResponse = await reSignIn(userId, token);
    if (reSignInResponse.success) {
      return reSignInResponse.user;
    }
  }
  return null;
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

(async () => {
  const router = sentryCreateBrowserRouter(
    [
      {
        path: '',
        element: <App />,
        children: [
          {
            path: '/',
            element: <HomePage />,
          },
          {
            path: '/players',
            element: <PlayersPage />,
          },
          {
            path: '/players/:playerId',
            element: <PlayerPage />,
            loader: playerDetailsLoader,
          },
          {
            path: '/tournaments',
            element: <TournamentsPage />,
          },
          {
            path: '/tournaments/:tournamentId',
            element: <TournamentPage />,
            loader: tournamentDetailsLoader,
          },
          {
            path: '/manage',
            element: <ManagePage />,
            children: [
              {
                index: true,
                path: 'videoIngestion',
                element: <ManageVideoIngestion />,
              },
              {
                path: 'playerIngestion',
                element: <ManagePlayerIngestion />,
              },
              {
                path: 'matchIngestion',
                element: <ManageMatchIngestion />,
              },
              {
                path: 'videos',
                element: <ManageVideos />,
                loader: manageVideosLoader,
              },
              {
                path: 'matches',
                element: <ManageMatches />,
                loader: manageMatchesLoader,
              },
              {
                path: 'tournaments',
                element: <ManageTournaments />,
              },
              {
                path: 'manual',
                element: <ManageManual />,
              },
            ],
          },
          {
            path: '/sign-up',
            element: <SignUpPage />,
          },
          {
            path: '/sign-in',
            element: <SignInPage />,
          },
          // {
          //   path: 'user-profile',
          //   element: <UserProfilePage />,
          // },
          // {
          //   path: 'forgot-password',
          //   element: <ForgotPasswordPage />,
          // },
          // {
          //   path: 'reset-password/email/:email/verifyCode/:verifyCode',
          //   element: <ResetPasswordPage />,
          // },
          // ...[
          //   'verify-email/email/:email/verifyCode/:verifyCode',
          //   'verify-email/email/:email',
          // ].map(path => ({
          //   path,
          //   element: <VerifyEmailPage />,
          // })),
        ],
      },
    ],
    {
      basename: '/',
    },
  );

  const user = await getUser();
  console.log('user: ', user);
  // const initialData = user ? await getInitialData() : DEFAULT_INITIAL_DATA;
  const initialData = await getInitialData();
  // const initialData = DEFAULT_INITIAL_DATA;

  createRoot(container).render(
    <AppStateProvider initialData={initialData} user={user}>
      <CssBaseline />
      <RouterProvider router={router} />
    </AppStateProvider>,
  );
})();
