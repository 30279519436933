import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

import {useAppState} from '#Client/context/AppState';

export const MainPageSnackbar = () => {
  const appState = useAppState();
  const {
    state: {snackbarText},
    closeSnackbar,
  } = appState;
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={6000}
      open={snackbarText !== null}
      onClose={closeSnackbar}
    >
      <Alert severity="success" sx={{width: '100%'}} onClose={closeSnackbar}>
        {snackbarText}
      </Alert>
    </Snackbar>
  );
};
