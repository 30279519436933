import {SxProps} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react';
import {Link} from 'react-router-dom';

import {useAppState} from '#Client/context/AppState';
import {Player} from '#Common/types';

type Props = {
  label: string;
  navigator?: boolean;
  selectedPlayer: Player | null;
  sx?: SxProps;
  onChange: (player: Player | null) => void;
};

export const PlayerFinder = ({
  label,
  navigator,
  selectedPlayer,
  sx,
  onChange,
}: Props) => {
  const appState = useAppState();
  const {
    state: {players},
  } = appState;

  if (navigator) {
    return (
      <Autocomplete
        clearOnBlur
        disablePortal
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.name) {
            return option.name;
          }
          // Regular option
          return option.name;
        }}
        handleHomeEndKeys
        options={players}
        renderInput={params => <TextField {...params} label={label} />}
        renderOption={(props, option) => {
          const {...optionProps} = props;
          return (
            <li {...optionProps} style={{padding: 0}}>
              <Link
                style={{
                  color: 'inherit',
                  textDecoration: 'none',
                  display: 'flex',
                  flex: 1,
                  padding: 8,
                }}
                to={`/player/${option.id}`}
              >
                {option.name}
              </Link>
            </li>
          );
        }}
        selectOnFocus
        sx={{width: 300}}
      />
    );
  }
  return (
    <Autocomplete
      getOptionLabel={(player: Player) => player.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      options={players}
      renderInput={params => <TextField {...params} label={label} />}
      sx={sx}
      value={selectedPlayer}
      onChange={(event, newValue: Player | null) => {
        onChange(newValue);
      }}
    />
  );
};
