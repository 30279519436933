import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input, {InputProps} from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import React from 'react';

type Props = {
  label: string;
  outlined?: boolean;
  type?: 'password' | 'number';
  onEnter?: () => void;
  onEscape?: () => void;
} & InputProps;

export const InputField = (props: Props) => {
  const {label, id, error, outlined, sx, onEnter, onEscape, ...rest} = props;
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (typeof onEnter === 'function' && event.key === 'Enter') {
      onEnter();
    }
    if (typeof onEscape === 'function' && event.key === 'Escape') {
      onEscape();
    }
  };
  return (
    <FormControl fullWidth sx={sx} variant={outlined ? 'outlined' : 'standard'}>
      <InputLabel error={error} htmlFor={id}>
        {label}
      </InputLabel>
      <Input onKeyUp={handleKeyUp} {...rest} />
      {error ? <FormHelperText>{id} is required.</FormHelperText> : null}
    </FormControl>
  );
};
