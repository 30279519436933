import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';

import {ProgressButton} from '#Client/components/ProgressButton';
import {
  StyledTableCell,
  StyledTableRow,
} from '#Client/components/TableComponents';
import {useAppState} from '#Client/context/AppState';
import {
  getRecentVideos,
  getYoutubePlaylistVideosUntilKey,
  ingestNewVideos,
} from '#Common/api';
import {NewVideo, Video} from '#Common/types';

export const genderToggleValues = ['women', 'men', 'mixed'] as const;
export type GenderToggleValue = (typeof genderToggleValues)[number];

const videoSources = ['PPA Youtube'] as const;
// type VideoSource = (typeof videoSources)[number];

export const ManageVideoIngestion = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [latestVideo, setLatestVideo] = useState<Video | null>(null);
  const [selectedSource, setSelectedSource] = useState<string>('PPA Youtube');
  const [videosToIngest, setVideosToIngest] = useState<NewVideo[]>([]);
  const [result, setResult] = useState<string>('');

  const appState = useAppState();
  const {setSnackbarText} = appState;

  const fetchLatestVideoKey = async () => {
    setIsLoading(true);
    const response = await getRecentVideos({
      page: 0,
      rowsPerPage: 1,
      showProcessedVideos: true,
    });
    if (response.success) {
      setLatestVideo(response.videos[0]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLatestVideoKey();
  }, []);

  const handleSourceChange = (event: SelectChangeEvent) => {
    setSelectedSource(event.target.value);
  };

  const onFetchClick = async () => {
    if (latestVideo === null) {
      return;
    }
    setIsLoading(true);
    const response = await getYoutubePlaylistVideosUntilKey(
      latestVideo.videoKey,
    );
    setIsLoading(false);
    const {reachedKey, videos} = response;
    if (reachedKey) {
      if (videos.length === 0) {
        setSnackbarText('No new videos to ingest');
      }
      setVideosToIngest(videos);
    } else {
      setSnackbarText('Did not fetch enough videos');
    }
  };

  const handleIngestClick = async () => {
    setIsLoading(true);
    const ingestResponse = await ingestNewVideos(videosToIngest);
    await fetchLatestVideoKey();
    setIsLoading(false);
    if (ingestResponse.success) {
      setResult(`Ingested ${videosToIngest.length} videos`);
      setVideosToIngest([]);
    }
  };

  return (
    <Paper>
      <Box pb={2} visibility={isLoading ? 'visible' : 'hidden'}>
        <LinearProgress />
      </Box>
      <Box p={2}>
        <FormControl fullWidth>
          <InputLabel id="video-source">Video Source</InputLabel>
          <Select
            label="Tournaments"
            labelId="video-source"
            value={selectedSource}
            onChange={handleSourceChange}
          >
            {videoSources.map(source => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography>
          Latest title: {latestVideo?.title} {latestVideo?.broadcastedDate}
        </Typography>
        <Stack direction="row" gap={2}>
          <ProgressButton
            disabled={latestVideo === null}
            isLoading={isLoading}
            variant="contained"
            onClick={onFetchClick}
          >
            Fetch Latest Videos
          </ProgressButton>
          <ProgressButton
            disabled={videosToIngest.length === 0}
            isLoading={isLoading}
            variant="contained"
            onClick={handleIngestClick}
          >
            Ingest {videosToIngest.length} videos
          </ProgressButton>
          <Typography>{result}</Typography>
        </Stack>
        {videosToIngest.length === 0 ? null : (
          <TableContainer component={Paper} sx={{marginTop: 2}}>
            <Table size="small" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Title</StyledTableCell>
                  <StyledTableCell>BroadcastedDate</StyledTableCell>
                  <StyledTableCell>VideoKey</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {videosToIngest.map(({title, broadcastedDate, videoKey}) => {
                  return (
                    <StyledTableRow key={videoKey}>
                      <StyledTableCell>{title}</StyledTableCell>
                      <StyledTableCell>{broadcastedDate}</StyledTableCell>
                      <StyledTableCell>{videoKey}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Paper>
  );
};
