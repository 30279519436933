import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Layout} from '#Client/features/Layout';
import {PlayerFinder} from '#Client/features/PlayerFinder/PlayerFinder';
import pickleballCourt from '#Client/images/pickleball-court.jpg';

export const HomePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${pickleballCourt})`,
          minHeight: '300px',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      >
        <Typography
          align="center"
          className="text-stroke"
          color="#fff"
          fontFamily="PT Sans"
          fontSize="5rem"
          fontWeight={700}
          margin="0 auto"
          variant="h2"
        >
          Pickleball Sidekick
        </Typography>
        <Typography
          align="center"
          className="text-stroke"
          color="#fff"
          fontFamily="PT Sans"
          fontSize="2.5rem"
          fontWeight={700}
          margin="0 auto"
          maxWidth="50%"
          variant="h4"
        >
          Pro pickleball tournament videos and information.
        </Typography>
      </Box>
      <Layout>
        <Stack
          gap={2}
          justifyContent="space-evenly"
          mt={-8}
          sx={{flexDirection: {xs: 'column', md: 'row'}}}
        >
          {/* <Paper sx={{borderRadius: 4}}> */}
          <Box
            bgcolor="var(--color-1)"
            borderRadius={4}
            color="#fff"
            flex={1}
            p={3}
          >
            <Typography variant="h5">See matches by player</Typography>
            <Typography variant="h6">
              Search for a player by name and view their matches.
            </Typography>
            <Paper>
              <Box mt={2} p={3}>
                <PlayerFinder
                  label="Player name"
                  selectedPlayer={null}
                  onChange={player => {
                    if (!player) {
                      return;
                    }
                    navigate(`/players/${player.id}`);
                  }}
                />
              </Box>
            </Paper>
          </Box>
          {/* </Paper> */}
          <Box
            bgcolor="var(--color-2)"
            borderRadius={4}
            color="#fff"
            flex={1}
            p={3}
          >
            <Typography variant="h5">See matches for a tournament</Typography>
            <Typography variant="h6">
              Search for a tournament and view any of the matches.
            </Typography>
          </Box>
          <Box
            bgcolor="var(--color-3)"
            borderRadius={4}
            color="#fff"
            flex={1}
            p={3}
          >
            <Typography variant="h5">See head to head</Typography>
            <Typography variant="h6">
              View matches where you know two, three, or four of the particular
              players.
            </Typography>
          </Box>
        </Stack>
      </Layout>
    </>
  );
};
