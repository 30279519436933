import {getPersistedState} from '#Common/persistence';
import {
  Candidate,
  Match,
  MATCH_FORMAT_NAME_ID_MAP,
  MATCH_ROUND_NAME_ID_MAP,
  MatchFormat,
  MatchRound,
  MatchWithVideoKey,
  NewMatch,
  NewVideo,
  Player,
  Tournament,
  User,
  Video,
  VideoType,
} from '#Common/types';

const ENVIRONMNET_BASE_URL_MAP: Record<string, string> = {
  development: `http://localhost:9063/api`,
  production: `https://pickleball-sidekick-api-837145415780.us-west1.run.app/api`,
};

const YOUTUBE_API_KEY = 'AIzaSyDIn1YnW4Z8hnmAzguQI0syx5NYplAbG2I';
const PPA_YOUTUBE_PLAYLIST_ID = 'UUSP6HlrMmRqogym2aHBPHpw';

const BASE_URL = ENVIRONMNET_BASE_URL_MAP[process.env.NODE_ENV || 'production'];

export const controller = new AbortController();

const makeRequest = async (url: string, requestInit?: RequestInit) => {
  const headers = await createHeaders();
  const {signal} = controller;
  return fetch(url, {...requestInit, headers, signal});
};

const createHeaders = async (): Promise<Headers> => {
  const persistedState = getPersistedState();
  const {userId, token} = persistedState;
  if (userId && token) {
    return new Headers({
      'Content-Type': 'application/json',
      credentials: 'include',
      Authorization: `${userId} ${token}`,
    });
  }
  return new Headers({
    'Content-Type': 'application/json',
    credentials: 'include',
  });
};

// Players
type GetPlayersResponse = {success: true; players: Player[]} | {success: false};
export const getAllPlayers = async (): Promise<GetPlayersResponse> => {
  return (await makeRequest(`${BASE_URL}/v1/players`)).json();
};

type GetPlayerDetailsResponse =
  | {success: true; player: Player}
  | {success: false};
export const getPlayerDetails = async (
  playerId: number,
): Promise<GetPlayerDetailsResponse> => {
  return (await makeRequest(`${BASE_URL}/v1/players/${playerId}`)).json();
};

type getExistingPlayerNamesResponse =
  | {success: true; results: {name: string; isMale: boolean}[]}
  | {success: false};
export const getExistingPlayerNames = async (
  names: string[],
): Promise<getExistingPlayerNamesResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/players/bynames?names=${names}`)
  ).json();
};

type CreatePlayersResponse = {success: boolean; results: string[]};
export const createPlayers = async (
  candidates: Candidate[],
): Promise<CreatePlayersResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/players`, {
      method: 'PUT',
      body: JSON.stringify({
        candidates,
      }),
    })
  ).json();
};

// Matches
type SearchMatchesResponse =
  | {success: true; matches: Match[]}
  | {success: false};
export const searchMatches = async (
  teamAPlayer1Id: number,
  teamAPlayer2Id: number,
  teamBPlayer1Id: number,
  teamBPlayer2Id: number,
  tournamentId: number,
): Promise<SearchMatchesResponse> => {
  return (
    await makeRequest(
      `${BASE_URL}/v1/matches/search?teamAPlayer1Id=${teamAPlayer1Id}&teamAPlayer2Id=${teamAPlayer2Id}&teamBPlayer1Id=${teamBPlayer1Id}&teamBPlayer2Id=${teamBPlayer2Id}&tournamentId=${tournamentId}`,
    )
  ).json();
};

type FindMatchesByPlayerResponse =
  | {success: true; matches: Match[]}
  | {success: false};
export const findMatchesByPlayer = async (
  playerId: number,
): Promise<FindMatchesByPlayerResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/matches/byplayer/${playerId}`)
  ).json();
};

type FindMatchesByTournamentResponse =
  | {success: true; matches: Match[]}
  | {success: false};
export const findMatchesByTournament = async (
  tournamentId: number,
): Promise<FindMatchesByTournamentResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/matches/bytournament/${tournamentId}`)
  ).json();
};

type CreateMatchesResponse = {
  success: boolean;
  insertCount: number;
  updatedCount: number;
};
export const createMatches = async (
  newMatches: NewMatch[],
): Promise<CreateMatchesResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/matches`, {
      method: 'POST',
      body: JSON.stringify({
        newMatches,
      }),
    })
  ).json();
};

type UpdateMatchVideoIdResponse = {success: boolean};
export const updateMatchVideoId = async (
  matchId: number,
  videoId: number,
): Promise<UpdateMatchVideoIdResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/matches/${matchId}/videoId`, {
      method: 'PUT',
      body: JSON.stringify({
        videoId,
      }),
    })
  ).json();
};

export type FetchMatchesFilter = {
  page: number;
  rowsPerPage: number;
  showProcessedMatches: boolean;
  tournamentId: number;
  format: MatchFormat;
  round: MatchRound;
};

export const DEFAULT_FETCH_MATCHES_FILTER: FetchMatchesFilter = {
  page: 0,
  rowsPerPage: 10,
  showProcessedMatches: true,
  tournamentId: 1,
  format: "Men's Doubles",
  round: 'Round of 16',
};

type GetMatchesResponse =
  | {
      success: true;
      matches: MatchWithVideoKey[];
      processedQuantity: number;
      totalQuantity: number;
    }
  | {success: false};

export const getMatches = async (
  filter: FetchMatchesFilter,
): Promise<GetMatchesResponse> => {
  const roundId = MATCH_ROUND_NAME_ID_MAP.get(filter.round);
  const formatId = MATCH_FORMAT_NAME_ID_MAP.get(filter.format);
  return (
    await makeRequest(
      `${BASE_URL}/v1/matches?rowsPerPage=${filter.rowsPerPage}&page=${filter.page}&showProcessedMatches=${filter.showProcessedMatches}&tournamentId=${filter.tournamentId}&formatId=${formatId}&roundId=${roundId}`,
    )
  ).json();
};

// Tournaments
type GetTournamentsResponse =
  | {success: true; tournaments: Tournament[]}
  | {success: false};
export const getAllTournaments = async (): Promise<GetTournamentsResponse> => {
  return (await makeRequest(`${BASE_URL}/v1/tournaments`)).json();
};

type UpdateTournamentsResponse =
  | {success: true; tournaments: Tournament[]}
  | {success: false};
export const updateTournamentPlayersIngestedDate = async (
  tournamentId: number,
): Promise<UpdateTournamentsResponse> => {
  return (
    await makeRequest(
      `${BASE_URL}/v1/tournaments/${tournamentId}/ingestPlayers`,
      {
        method: 'PUT',
      },
    )
  ).json();
};

export const updateTournamentMatchesIngestedDate = async (
  tournamentId: number,
): Promise<UpdateTournamentsResponse> => {
  return (
    await makeRequest(
      `${BASE_URL}/v1/tournaments/${tournamentId}/ingestMatches`,
      {
        method: 'PUT',
      },
    )
  ).json();
};

// Videos
type GetRecentVideosResponse =
  | {
      success: true;
      processedQuantity: number;
      totalQuantity: number;
      videos: Video[];
    }
  | {success: false};

export type FetchVideosFilter = {
  page: number;
  rowsPerPage: number;
  showProcessedVideos: boolean;
};
export const DEFAULT_FETCH_VIDEOS_FILTER = {
  page: 0,
  rowsPerPage: 500,
  showProcessedVideos: true,
};
export const getRecentVideos = async (
  filter: FetchVideosFilter,
): Promise<GetRecentVideosResponse> => {
  return (
    await makeRequest(
      `${BASE_URL}/v1/videos?rowsPerPage=${filter.rowsPerPage}&page=${filter.page}&showProcessedVideos=${filter.showProcessedVideos}`,
    )
  ).json();
};

type SkipVideoResponse = {success: boolean};
export const skipVideo = async (
  videoId: number,
): Promise<SkipVideoResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/videos/${videoId}/skip`, {
      method: 'PUT',
    })
  ).json();
};

type IngestNewVideosResponse = {success: boolean};
export const ingestNewVideos = async (
  newVideos: NewVideo[],
): Promise<IngestNewVideosResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/videos`, {
      method: 'POST',
      body: JSON.stringify({
        newVideos,
      }),
    })
  ).json();
};

type SearchVideosResponse = {success: true; videos: Video[]} | {success: false};
export const searchVideos = async (
  matchId: number,
): Promise<SearchVideosResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/videos/search?matchId=${matchId}`)
  ).json();
};

// TournamentVideos
type CreateTournamentVideoResponse = {success: boolean};
export const createTournamentVideo = async (
  tournamentId: number,
  videoId: number,
  videoType: Omit<VideoType, 'matches'>,
  teamAPlayer1Id: number | null,
  teamAPlayer2Id: number | null,
  teamBPlayer1Id: number | null,
  teamBPlayer2Id: number | null,
): Promise<CreateTournamentVideoResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/tournamentVideos`, {
      method: 'POST',
      body: JSON.stringify({
        tournamentId,
        videoId,
        videoType,
        teamAPlayer1Id,
        teamAPlayer2Id,
        teamBPlayer1Id,
        teamBPlayer2Id,
      }),
    })
  ).json();
};

// Match Videos
type CreateMatchVideoResponse = {success: boolean};
export const createMatchVideo = async (
  videoId: number,
  tournamentId: number,
  startTime: number | null,
  format: MatchFormat,
  teamAPlayerId1: number,
  teamAPlayerId2: number | null,
  teamBPlayerId1: number,
  teamBPlayerId2: number | null,
  didPlayer1Win: boolean,
): Promise<CreateMatchVideoResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/matchVideos`, {
      method: 'POST',
      body: JSON.stringify({
        videoId,
        tournamentId,
        startTime,
        format,
        teamAPlayerId1,
        teamAPlayerId2,
        teamBPlayerId1,
        teamBPlayerId2,
        didPlayer1Win,
      }),
    })
  ).json();
};

const getYoutubePlaylistVideosByPageToken = async (
  pageToken: string,
): Promise<{
  nextPageToken: string;
  items: {
    snippet: {
      publishedAt: string;
      resourceId: {
        videoId: string;
      };
      title: string;
    };
  }[];
}> => {
  const url = `https://www.googleapis.com/youtube/v3/playlistItems?key=${YOUTUBE_API_KEY}&part=snippet&maxResults=50&playlistId=${PPA_YOUTUBE_PLAYLIST_ID}&fields=items(snippet(publishedAt%2CresourceId%2FvideoId%2Ctitle))%2CnextPageToken&pageToken=${pageToken}`;
  return (
    await fetch(url, {
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
  ).json();
};

export const getYoutubePlaylistVideosUntilKey = async (
  videoKey: string,
): Promise<{videos: NewVideo[]; reachedKey: boolean}> => {
  let reachedKey = false;
  let nextPageToken = '';
  const videos: NewVideo[] = [];
  outer: for (let i = 0; i < 10; i++) {
    const results = await getYoutubePlaylistVideosByPageToken(nextPageToken);
    nextPageToken = results.nextPageToken;
    const {items} = results;
    for (const item of items) {
      if (item.snippet.resourceId.videoId === videoKey) {
        reachedKey = true;
        break outer;
      }
      videos.push({
        title: item.snippet.title,
        videoKey: item.snippet.resourceId.videoId,
        broadcastedDate: new Date(item.snippet.publishedAt)
          .toISOString()
          .split('T')[0],
        website: 'yt',
        processedDate: null,
      });
    }
  }
  return {
    videos: videos.reverse(),
    reachedKey,
  };
};

type SignUpResponse =
  | {success: true; user: User}
  | {success: false; reason: string};

export const signUp = async (
  name: string,
  email: string,
  password: string,
): Promise<SignUpResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/auth/signup`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        email,
        password,
      }),
    })
  ).json();
};

type SignInResponse = {success: true; user: User} | {success: false};

export const signIn = async (
  email: string,
  password: string,
): Promise<SignInResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/auth/signin`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    })
  ).json();
};

type ReSignInResponse = {success: true; user: User} | {success: false};

export const reSignIn = async (
  userId: number,
  token: string,
): Promise<ReSignInResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/auth/resignin`, {
      method: 'POST',
      body: JSON.stringify({
        userId,
        token,
      }),
    })
  ).json();
};

type VerifyUserResponse =
  | {success: true; user: User}
  | {success: false; reason: string};

export const verifyUser = async (
  email: string,
  verifyCode: string,
): Promise<VerifyUserResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/auth/user/verify`, {
      method: 'PUT',
      body: JSON.stringify({
        email,
        verifyCode,
      }),
    })
  ).json();
};

type InitiateForgotPasswordResponse =
  | {success: true; user: User}
  | {success: false; reason: string};

export const initiateForgotPassword = async (
  email: string,
): Promise<InitiateForgotPasswordResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/auth/user/forgotpassword`, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    })
  ).json();
};

type InitiateResetPasswordResponse =
  | {success: true; user: User}
  | {success: false; reason: string};

export const initiateResetPassword = async (
  email: string,
  verifyCode: string,
  password: string,
): Promise<InitiateResetPasswordResponse> => {
  return (
    await makeRequest(`${BASE_URL}/v1/auth/user/resetpassword`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        verifyCode,
        password,
      }),
    })
  ).json();
};
