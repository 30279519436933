import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import React from 'react';

import {MatchRound, matchRounds} from '#Common/types';
import {assertValueIsEnum} from '#Common/util';

type Props = {
  id?: string;
  label?: string;
  value: MatchRound;
  onChange: (value: MatchRound) => void;
};

export const RoundPicker = ({id, label = 'Round', value, onChange}: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const {value} = event.target;
    if (!assertValueIsEnum<MatchRound>(value, matchRounds)) {
      return;
    }
    onChange(value);
  };
  return (
    <FormControl fullWidth>
      {label ? <InputLabel id={id}>{label}</InputLabel> : null}
      <Select label={label} labelId={id} value={value} onChange={handleChange}>
        {matchRounds.map(matchRound => (
          <MenuItem key={matchRound} value={matchRound}>
            {matchRound}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
