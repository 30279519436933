import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import type {ActionFunction} from 'react-router';
import {useLoaderData} from 'react-router-dom';

import {ProgressButton} from '#Client/components/ProgressButton';
import {ScoreTable} from '#Client/components/ScoreTable';
import {
  StyledTableCell,
  StyledTableRow,
} from '#Client/components/TableComponents';
import {YoutubeVideo} from '#Client/components/YoutubeVideo';
import {useAppState} from '#Client/context/AppState';
import {
  VideoData,
  VideosTable,
} from '#Client/features/ManagePage/ManageVideos/VideosTable';
import {PlayerFinder} from '#Client/features/PlayerFinder/PlayerFinder';
import {TournamentPicker} from '#Client/features/TournamentPicker/TournamentPicker';
import {
  createTournamentVideo,
  DEFAULT_FETCH_VIDEOS_FILTER,
  FetchVideosFilter,
  getRecentVideos,
  searchMatches,
  skipVideo,
  updateMatchVideoId,
} from '#Common/api';
import {
  Match,
  Player,
  Tournament,
  Video,
  VideoType,
  videoTypes,
} from '#Common/types';
import {assertValueIsEnum} from '#Common/util';

// const VIDEO_QTY_TO_FETCH = 1000;

const TOURNAMENT_INDEXES = {
  BRISTOL: 0,
  PICKLR_UTAH: 1,
};

export const ManageVideos = () => {
  const initialVideoData = useLoaderData() as VideoData;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const appState = useAppState();
  const {
    state: {players, tournaments},
    setSnackbarText,
  } = appState;

  const [selectedVideo, setSelectedVideo] = useState<Video>(
    initialVideoData.videos[0],
  );

  const [selectedTournament, setSelectedTournament] = useState<Tournament>(
    tournaments[0],
  );

  const [team1Player1, setTeam1Player1] = useState<Player | null>(null);
  const [team1Player2, setTeam1Player2] = useState<Player | null>(null);
  const [team2Player1, setTeam2Player1] = useState<Player | null>(null);
  const [team2Player2, setTeam2Player2] = useState<Player | null>(null);
  const [latestProcessedPlayers, setLatestProcessedPlayers] = useState<
    [Player | null, Player | null, Player | null, Player | null]
  >([null, null, null, null]);

  const resetPlayers = () => {
    setTeam1Player1(null);
    setTeam1Player2(null);
    setTeam2Player1(null);
    setTeam2Player2(null);
  };

  const [selectedVideoType, setSelectedVideoType] =
    useState<VideoType>('match');

  // const [markAsProcessed, setMarkAsProcessed] = useState<boolean>(true);
  const [matchSearchResults, setMatchSearchResults] = useState<Match[]>([]);
  const [selectedMatchId, setSelectedMatchId] = useState<number>(-1);
  const [videoData, setVideoData] = useState<VideoData>(initialVideoData);
  const [fetchVideosFilter, setFetchVideosFilter] = useState<FetchVideosFilter>(
    DEFAULT_FETCH_VIDEOS_FILTER,
  );

  const fetchVideos = async (newFilter: FetchVideosFilter) => {
    setIsLoading(true);
    const response = await getRecentVideos(newFilter);
    setIsLoading(false);
    if (response.success) {
      setVideoData({
        videos: response.videos,
        totalQuantity: response.totalQuantity,
        processedQuantity: response.processedQuantity,
      });
      // handleRowClick(selectedVideoIndex - 1);
    }
  };

  const findMatch = (
    teamString: string,
    index: 0 | 1,
    // setter: (value: React.SetStateAction<Player | null>) => void,
  ): Player | null => {
    const teamSplit = teamString.split('/');
    if (teamSplit.length === 2) {
      const name = teamSplit[index];

      const nameMatches = players.filter(
        player => player.name.indexOf(name) !== -1,
      );
      if (nameMatches.length === 1) {
        const player = nameMatches[0];
        // setter(player);
        return player;
      }
      if (nameMatches.length === 0) {
        // setter(null);
        return null;
      }
    }
    // setter(null);
    return null;
  };

  const handleSelectedVideoChange = async (video: Video) => {
    setSelectedVideo(video);
    setSelectedMatchId(-1);
    const tournament = identifyTournament(video);
    if (tournament) {
      setSelectedTournament(tournament);
    }
    const [t1p1, t1p2, t2p1, t2p2] = identifyPlayers(video);
    if (selectedVideoType === 'highlight') {
      if (t1p1 !== null) {
        setTeam1Player1(t1p1);
      }
      if (t1p2 !== null) {
        setTeam1Player2(t1p2);
      }
      if (t2p1 !== null) {
        setTeam2Player1(t2p1);
      }
      if (t2p2 !== null) {
        setTeam2Player2(t2p2);
      }
    } else if (selectedVideoType === 'match') {
      setIsLoading(true);
      const results = await searchMatches(
        t1p1?.id || -1,
        t1p2?.id || -1,
        t2p1?.id || -1,
        t2p2?.id || -1,
        tournament?.id || -1,
      );
      setIsLoading(false);
      if (results.success) {
        setMatchSearchResults(results.matches);
        if (results.matches.length === 1) {
          setSelectedMatchId(results.matches[0].id);
        }
      } else {
        setSnackbarText('error');
        console.log('results: ', results);
      }
    }
  };

  const findTwoTeams = (input: string): null | [string, string] => {
    const splitter =
      input.indexOf(' v ') > -1
        ? ' v '
        : input.indexOf(' vs ') > -1
        ? ' vs '
        : null;
    if (splitter === null) {
      return null;
    }
    const output = input.split(splitter);
    if (output.length > 1) {
      return [output[0], output[1]];
    }
    return null;
  };

  const identifyTournament = (video: Video): Tournament | null => {
    const {title} = video;
    if (title.indexOf('Bristol') >= 0) {
      // setSelectedTournament(tournaments[TOURNAMENT_INDEXES.BRISTOL]);
      return tournaments[TOURNAMENT_INDEXES.BRISTOL];
    }
    if (title.indexOf('Picklr Utah') >= 0) {
      // setSelectedTournament(tournaments[TOURNAMENT_INDEXES.PICKLR_UTAH]);
      return tournaments[TOURNAMENT_INDEXES.PICKLR_UTAH];
    }
    return null;
  };

  const identifyPlayers = (
    video: Video,
  ): [Player | null, Player | null, Player | null, Player | null] => {
    const results: [
      Player | null,
      Player | null,
      Player | null,
      Player | null,
    ] = [null, null, null, null];
    resetPlayers();
    const snackbarItems: string[] = [];
    const {title} = video;
    const beforeAt = title.split(' at ')[0];
    const twoTeams = findTwoTeams(beforeAt);
    if (twoTeams !== null) {
      const [team1, team2] = twoTeams;
      if (team1.indexOf('/') === -1 && team1.indexOf('/') === -1) {
        // Singles
        const firstPlayerMatches = players.filter(
          player => player.name === team1,
        );
        if (firstPlayerMatches.length === 1) {
          const player = firstPlayerMatches[0];
          // const format = player.isMale ? "Men's Singles" : "Women's Singles";
          // setTeam1Player1(player);
          results[0] = player;
          // setSelectedMatchFormat(format);
          // } else {
          // snackbarItems.push(`Couldn't find player 1: ${team1}`);
        }
        const secondPlayer = players.filter(player => player.name === team2);
        if (secondPlayer.length === 1) {
          const player = secondPlayer[0];
          // setTeam2Player1(player);
          results[2] = player;
          // setSelectedMatchFormat(
          //   player.isMale ? "Men's Singles" : "Women's Singles",
          // );
          // } else {
          // snackbarItems.push(`Couldn't find player 2: ${team2}`);
        }
      } else {
        // Doubles
        // const t1p1 = findMatch(team1, 0, setTeam1Player1);
        // const t1p2 = findMatch(team1, 1, setTeam1Player2);
        const t1p1 = findMatch(team1, 0);
        const t1p2 = findMatch(team1, 1);
        if (t1p1) {
          results[0] = t1p1;
        }
        if (t1p2) {
          results[1] = t1p2;
        }
        // if (t1p1 && t1p2) {
        //   setSelectedMatchFormat(
        //     t1p1.isMale === t1p2.isMale
        //       ? t1p1.isMale
        //         ? "Men's Doubles"
        //         : "Women's Doubles"
        //       : 'Mixed Doubles',
        //   );
        // } else {
        //   snackbarItems.push(`Couldn't find team 1 players: ${team1}`);
        // }

        // const t2p1 = findMatch(team2, 0, setTeam2Player1);
        // const t2p2 = findMatch(team2, 1, setTeam2Player2);
        const t2p1 = findMatch(team2, 0);
        const t2p2 = findMatch(team2, 1);
        if (t2p1) {
          results[2] = t2p1;
        }
        if (t2p2) {
          results[3] = t2p2;
        }
        // if (t2p1 && t2p2) {
        //   setSelectedMatchFormat(
        //     t2p1.isMale === t2p2.isMale
        //       ? t2p1.isMale
        //         ? "Men's Doubles"
        //         : "Women's Doubles"
        //       : 'Mixed Doubles',
        //   );
        // } else {
        //   snackbarItems.push(`Couldn't find team 2 players: ${team2}`);
        // }
      }
    }
    if (snackbarItems.length > 0) {
      setSnackbarText(snackbarItems.join('\n'));
    }
    return results;
  };

  const restoreLatestProcessedPlayers = () => {
    setTeam1Player1(latestProcessedPlayers[0]);
    setTeam1Player2(latestProcessedPlayers[1]);
    setTeam2Player1(latestProcessedPlayers[2]);
    setTeam2Player2(latestProcessedPlayers[3]);
  };

  const handleProcess = async () => {
    setLatestProcessedPlayers([
      team1Player1,
      team1Player2,
      team2Player1,
      team2Player2,
    ]);
    if (selectedVideoType === 'match') {
      if (selectedMatchId === -1) {
        return;
      }
      setIsLoading(true);
      const updateMatchVideoIdResponse = await updateMatchVideoId(
        selectedMatchId,
        selectedVideo.id,
      );
      setIsLoading(false);
      if (updateMatchVideoIdResponse.success) {
        setSnackbarText('Success');
        await fetchVideos(fetchVideosFilter);
        resetPlayers();
      } else {
        setSnackbarText('Error');
      }
    } else if (selectedVideoType === 'highlight') {
      // const tournament = tournaments[selectedTournamentIndex];
      setIsLoading(true);
      const response = await createTournamentVideo(
        selectedTournament.id,
        selectedVideo.id,
        selectedVideoType,
        team1Player1?.id || null,
        team1Player2?.id || null,
        team2Player1?.id || null,
        team2Player2?.id || null,
      );
      setIsLoading(false);
      if (response.success) {
        await fetchVideos(fetchVideosFilter);
        resetPlayers();
      }
    } else {
      // const tournament = tournaments[selectedTournamentIndex];
      setIsLoading(true);
      const response = await createTournamentVideo(
        selectedTournament.id,
        selectedVideo.id,
        selectedVideoType,
        null,
        null,
        null,
        null,
      );
      setIsLoading(false);
      if (response.success) {
        await fetchVideos(fetchVideosFilter);
        resetPlayers();
      }
    }
  };

  const handleSkipVideo = async () => {
    // const video = videoData.videos[selectedVideoIndex];
    setIsLoading(true);
    const response = await skipVideo(selectedVideo.id);
    setIsLoading(false);
    if (response.success) {
      // await fetchVideos();
    }
  };

  const handleSetFetchVideosFilter = async (
    newFetchVideosFilter: FetchVideosFilter,
  ) => {
    setFetchVideosFilter(newFetchVideosFilter);
    fetchVideos(newFetchVideosFilter);
  };

  return (
    <>
      {selectedVideo === null ? null : (
        <>
          <VideosTable
            fetchVideosFilter={fetchVideosFilter}
            isLoading={isLoading}
            selectedVideo={selectedVideo}
            videoData={videoData}
            onSelectedVideoChange={handleSelectedVideoChange}
            onSetFetchVideosFilter={handleSetFetchVideosFilter}
          />
          <>
            <Stack
              gap={2}
              mt={2}
              sx={{flexDirection: {xs: 'column', md: 'row'}}}
            >
              <Box
                flex={1}
                sx={{opacity: isLoading ? 0.5 : 1}}
                textAlign="center"
              >
                {selectedVideo.website === 'yt' ? (
                  <YoutubeVideo videoKey={selectedVideo.videoKey} />
                ) : null}
              </Box>
              <Box flex={1}>
                <Paper>
                  <Box p={2}>
                    <ToggleButtonGroup
                      aria-label="text alignment"
                      exclusive
                      value={selectedVideoType}
                      onChange={event => {
                        if (!(event.target instanceof HTMLButtonElement)) {
                          return;
                        }
                        const newVideoType = event.target.value;
                        if (
                          !assertValueIsEnum<VideoType>(
                            newVideoType,
                            videoTypes,
                          )
                        ) {
                          return;
                        }
                        setSelectedVideoType(newVideoType);
                      }}
                    >
                      {videoTypes.map(videoType => (
                        <ToggleButton
                          color="primary"
                          key={videoType}
                          sx={{textTransform: 'capitalize'}}
                          value={videoType}
                        >
                          {videoType}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                    {['match', 'highlight', 'recap', 'preview'].includes(
                      selectedVideoType,
                    ) ? (
                      <Box mt={2}>
                        <TournamentPicker
                          label="Tournament"
                          selectedTournament={selectedTournament}
                          onChange={tournament => {
                            if (tournament) {
                              setSelectedTournament(tournament);
                            }
                          }}
                        />
                      </Box>
                    ) : null}
                    {selectedVideoType === 'match' ? (
                      <TableContainer
                        component={Paper}
                        sx={{mt: 2, opacity: isLoading ? 0.5 : 1}}
                      >
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell>Selected</StyledTableCell>
                              <StyledTableCell>Scorecard</StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {matchSearchResults.map(match => {
                              const {id} = match;
                              return (
                                <StyledTableRow
                                  key={id}
                                  sx={{cursor: 'pointer'}}
                                  onClick={() => setSelectedMatchId(id)}
                                >
                                  <StyledTableCell sx={{textAlign: 'center'}}>
                                    <Radio
                                      checked={selectedMatchId === id}
                                      name="radio-buttons"
                                      value={id}
                                    />
                                    {id}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <ScoreTable match={match} />
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : null}
                    {selectedVideoType === 'highlight' ? (
                      <>
                        <Box mt={2}>
                          <Box mt={1}>
                            <PlayerFinder
                              label="Team A Player 1"
                              selectedPlayer={team1Player1}
                              onChange={setTeam1Player1}
                            />
                          </Box>
                          <Box mt={1}>
                            <PlayerFinder
                              label="Team A Player 2"
                              selectedPlayer={team1Player2}
                              onChange={setTeam1Player2}
                            />
                          </Box>
                          <Typography mt={1}>VS</Typography>
                          <Box mt={1}>
                            <PlayerFinder
                              label="Team B Player 1"
                              selectedPlayer={team2Player1}
                              onChange={setTeam2Player1}
                            />
                          </Box>
                          <Box mt={1}>
                            <PlayerFinder
                              label="Team B Player 2"
                              selectedPlayer={team2Player2}
                              onChange={setTeam2Player2}
                            />
                          </Box>
                        </Box>
                      </>
                    ) : null}
                    <Divider sx={{marginTop: 2}} />
                    <Stack
                      alignContent="center"
                      direction="row"
                      justifyContent="space-between"
                      mt={2}
                    >
                      {/* <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={markAsProcessed}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setMarkAsProcessed(event.target.checked);
                              }}
                            />
                          }
                          label="Mark video as processed"
                        />
                      </FormGroup> */}
                      <Stack direction="row" gap={2}>
                        <ProgressButton
                          isLoading={isLoading}
                          variant="outlined"
                          onClick={restoreLatestProcessedPlayers}
                        >
                          Restore{' '}
                          {latestProcessedPlayers
                            .map(player => player?.name || 'null')
                            .join(' / ')}
                        </ProgressButton>
                        <ProgressButton
                          color="error"
                          isLoading={isLoading}
                          variant="contained"
                          onClick={handleSkipVideo}
                        >
                          Skip
                        </ProgressButton>
                        <ProgressButton
                          isLoading={isLoading}
                          variant="contained"
                          onClick={handleProcess}
                        >
                          Submit
                        </ProgressButton>
                      </Stack>
                    </Stack>
                  </Box>
                </Paper>
              </Box>
            </Stack>
          </>
          {/* ) : null} */}
        </>
      )}
    </>
  );
};

export const manageVideosLoader: ActionFunction = async () => {
  const videoResponse = await getRecentVideos(DEFAULT_FETCH_VIDEOS_FILTER);
  if (!videoResponse.success) {
    throw 'Error getting videos';
  }
  return videoResponse;
};
