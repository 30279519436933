import {CircularProgress} from '@mui/material';
import Box from '@mui/material/Box';
import Button, {ButtonProps} from '@mui/material/Button';
import React from 'react';

type ProgressButtonProps = ButtonProps & {
  isLoading: boolean;
};

export const ProgressButton = ({
  children,
  disabled,
  isLoading,
  ...rest
}: ProgressButtonProps) => (
  <Box display="inline-block" position="relative">
    <Button {...rest} disabled={disabled || isLoading}>
      {children}
    </Button>
    {isLoading && (
      <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    )}
  </Box>
);
