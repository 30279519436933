import Box from '@mui/material/Box';
import React from 'react';

import {NavLink} from '#Client/components/NavLink';

export const Footer = () => (
  <Box
    borderTop="1px solid #000"
    color="#fff"
    component="footer"
    display="flex"
    justifyContent="flex-end"
    marginTop="auto"
    p={2}
    sx={{backgroundColor: 'var(--color-1)'}}
  >
    <Box mr="auto">&copy; Pickleball Sidekick</Box>
    <Box display="flex" gap={2}>
      <NavLink to="#">About us</NavLink>
      <NavLink to="#">Contact</NavLink>
      <NavLink to="#">Privacy and terms</NavLink>
    </Box>
  </Box>
);
