import Stack from '@mui/material/Stack';
import React from 'react';
import {Link, Outlet, useMatch} from 'react-router-dom';

import {Layout} from '#Client/features/Layout';

export const ManagePage = () => {
  const links = [
    {
      to: '/manage/videoIngestion',
      name: 'Video Ingestion',
    },
    {
      to: '/manage/playeringestion',
      name: 'Player Ingestion',
    },
    {
      to: '/manage/matchIngestion',
      name: 'Match Ingestion',
    },
    {
      to: '/manage/videos',
      name: 'Videos',
    },
    {
      to: '/manage/matches',
      name: 'Matches',
    },
    {
      to: '/manage/tournaments',
      name: 'Tournaments',
    },
    {
      to: '/manage/manual',
      name: 'Manual',
    },
  ];
  // sx={{flexDirection: {xs: 'column', lg: 'row'}}}
  return (
    <Layout>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        mb={2}
        sx={{fontSize: {xs: '16px', lg: '20px'}}}
      >
        {links.map(({to, name}) => (
          <Link
            key={name}
            style={{
              color: 'inherit',
              cursor: useMatch(to) ? 'default' : 'pointer',
              textDecoration: useMatch(to) ? 'none' : 'underline',
              fontWeight: useMatch(to) ? 'bold' : 'normal',
            }}
            to={to}
          >
            {name}
          </Link>
        ))}
      </Stack>
      <Outlet />
    </Layout>
  );
};
