import {SxProps} from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import type {ActionFunction} from 'react-router';
import {useLoaderData} from 'react-router-dom';

import {ProgressButton} from '#Client/components/ProgressButton';
import {
  StyledTableCell,
  StyledTableRow,
} from '#Client/components/TableComponents';
import {YoutubeVideo} from '#Client/components/YoutubeVideo';
import {
  MatchData,
  MatchesTable,
} from '#Client/features/ManagePage/ManageMatches/MatchesTable';
import {
  DEFAULT_FETCH_MATCHES_FILTER,
  FetchMatchesFilter,
  getMatches,
  searchVideos,
  updateMatchVideoId,
} from '#Common/api';
import {Match, Video} from '#Common/types';
import {formatDate, timeAgo} from '#Common/util';

export const ManageMatches = () => {
  const initialMatchData = useLoaderData() as MatchData;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matchData, setMatchData] = useState<MatchData>(initialMatchData);
  const [fetchMatchesFilter, setFetchMatchesFilter] =
    useState<FetchMatchesFilter>(DEFAULT_FETCH_MATCHES_FILTER);
  const [selectedMatch, setSelectedMatch] = useState<Match>(
    initialMatchData.matches[0],
  );
  const [searchResultVideos, setSearchResultVideos] = useState<Video[]>([]);

  const fetchMatches = async (filter: FetchMatchesFilter) => {
    setIsLoading(true);
    const response = await getMatches(filter);
    setIsLoading(false);
    if (response.success) {
      setMatchData({
        matches: response.matches,
        totalQuantity: response.totalQuantity,
        processedQuantity: response.processedQuantity,
      });
    }
  };

  const handleSetFetchVideosFilter = async (
    newFetchMatchesFilter: FetchMatchesFilter,
  ) => {
    setFetchMatchesFilter(newFetchMatchesFilter);
    fetchMatches(newFetchMatchesFilter);
  };

  const handleSelectedMatchChange = async (match: Match) => {
    setSelectedMatch(match);
    setIsLoading(true);
    const response = await searchVideos(match.id);
    setIsLoading(false);
    if (response.success) {
      setSearchResultVideos(response.videos);
    }
  };

  const handleAssignClick = async (videoId: number) => {
    setIsLoading(true);
    const response = await updateMatchVideoId(selectedMatch.id, videoId);
    if (response.success) {
      await fetchMatches(fetchMatchesFilter);
    }
    setIsLoading(false);
  };
  return (
    <>
      <Box pb={2} visibility={isLoading ? 'visible' : 'hidden'}>
        <LinearProgress />
      </Box>
      <Stack gap={2} mt={2} sx={{flexDirection: {xs: 'column', md: 'row'}}}>
        <Box flex={1}>
          <MatchesTable
            fetchMatchesFilter={fetchMatchesFilter}
            isAdmin
            isLoading={isLoading}
            matchData={matchData}
            selectedMatch={selectedMatch}
            onSelectedMatchChange={handleSelectedMatchChange}
            onSetFetchMatchesFilter={handleSetFetchVideosFilter}
          />
        </Box>
        <Box flex={1}>
          {searchResultVideos.length === 0 ? (
            <Typography>No videos found.</Typography>
          ) : (
            <TableContainer
              component={Paper}
              sx={{maxHeight: 400, opacity: isLoading ? 0.5 : 1}}
            >
              <Table size="small" stickyHeader>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Info</StyledTableCell>
                    <StyledTableCell sx={{width: '50%'}}>Video</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {searchResultVideos.map((video, index) => {
                    const {
                      id,
                      videoKey,
                      title,
                      broadcastedDate,
                      processedDate,
                    } = video;
                    // const isSelected = id === selectedVideo.id;
                    const baseCellStyles: SxProps = {cursor: 'pointer'};
                    // const selectedCellStyles: SxProps = isSelected
                    //   ? {backgroundColor: 'var(--color-5)'}
                    //   : {};
                    // const cellStyles = {...baseCellStyles, ...selectedCellStyles};
                    return (
                      <StyledTableRow
                        key={`${index}-${title}`}
                        // onClick={() => onSelectedVideoChange(video)}
                      >
                        <StyledTableCell sx={baseCellStyles}>
                          <Box>{title}</Box>
                          <Box>{formatDate(broadcastedDate)}</Box>
                          <Box>
                            {processedDate === null
                              ? 'No'
                              : timeAgo(processedDate)}
                          </Box>
                          <ProgressButton
                            disabled={selectedMatch.videoId !== null}
                            isLoading={isLoading}
                            variant="contained"
                            onClick={() => {
                              handleAssignClick(id);
                            }}
                          >
                            Assign
                          </ProgressButton>
                        </StyledTableCell>
                        <StyledTableCell sx={baseCellStyles}>
                          <YoutubeVideo videoKey={videoKey} />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Stack>
    </>
  );
};

export const manageMatchesLoader: ActionFunction = async () => {
  const matchesResponse = await getMatches(DEFAULT_FETCH_MATCHES_FILTER);
  if (!matchesResponse.success) {
    throw 'Error getting matches';
  }
  return matchesResponse;
};
